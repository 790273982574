import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from 'hooks/StoreReduxHooks';
import { useWindowSize } from 'hooks/WindowResize';

import { ICalendarRangeProps } from 'app/AppTypes';

import CanvasRoadmap from 'components/Canvas/CanvasRoadmap/CanvasRoadmap';
import MenuBar from 'components/MenuBar';
import CanvasRoadmapMainBar from 'components/Canvas/CanvasRoadmap/CanvasRoadmapMainBar';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import {
  Box,
  Stack
} from '@mui/material';

import 'App.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

export default function Roadmap() {
  //Redux States
  const userSession = useAppSelector((state) => state.appSession.user);
  const companySession = useAppSelector((state) => state.appSession.company);
  //Redux States

  const [width, height] = useWindowSize();

  const [canvasWidth, setCanvasWidth] = useState<number>(window.innerWidth);
  const [canvasHeight, setCanvasHeight] = useState<number>(window.innerHeight);

  const [filterTeams, setFilterTeams] = useState<number[]>([]);
  const [filterVersions, setFilterVersions] = useState<number[]>([]);
  const [calendarRange, setCalendarRange] = useState<ICalendarRangeProps>({} as ICalendarRangeProps);
  
  function resizeWindow(): void {
    setCanvasWidth(width);
    setCanvasHeight(height);
  }

  useEffect(() => {
    resizeWindow();
  }, [width, height]);

  // useEffect(() => {
  //   resizeWindow();
  // }, [openDetailsScreenWide]); //When Details Drawer Screen Wide changes resize Window


  if (!userSession.hasActiveSession) {
    return <Navigate to="/login" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: "#EDEDED" }}>

        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0}>
          <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
            <Box sx={{ border: 0.1 }} >
              <CanvasRoadmap
                canvasViewName={"Default"}
                canvasWidth={canvasWidth} canvasHeight={canvasHeight}
                calendarRange={calendarRange}
                filters={{ teams: filterTeams, versions: filterVersions }}
              />
            </Box>
          </Stack>
        </Stack>

        <CanvasRoadmapMainBar 
          onChangeTeams={(teamsSelected: number[]) => { setFilterTeams(teamsSelected); }} 
          onChangeVersions={(versionsSelected: number[]) => { setFilterVersions(versionsSelected); }}
          onChangeCalendarView={(calendarRange: ICalendarRangeProps) => { 
            //console.log("Parent calendarRange: ", calendarRange);
            setCalendarRange(calendarRange); 
          }}
        />

        <MenuBar pinBar={false}/>
        {/* 
        <DetailDrawer /> 
        <AgileBoardDrawer />
        */}

      </Box>
    </ThemeProvider>
  );
}
