import React, { useEffect, useState } from 'react'; // let's also import Component
import { useForm, Controller } from 'react-hook-form';

import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';

import { ICalendarRangeProps } from 'app/AppTypes';

import {
  Stack,
  Box,
  InputLabel,
  IconButton,
} from '@mui/material';

import MainBarDrawer from 'components/MainBarDrawer';
import FormNewVersion from 'components/Forms/FormNewVersion'
import SelectorTeams from 'components/SelectorTeams';

import Filter1Icon from '@mui/icons-material/Filter1';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter6Icon from '@mui/icons-material/Filter6';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';


import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateUtils } from 'app/DateUtils';

dayjs.extend(utc);
dayjs.extend(timezone);

type ICanvasRoadmapMainBarProps = {
  onChangeTeams: (teamsSelected: number[]) => void,
  onChangeVersions: (versionsSelected: number[]) => void,
  onChangeCalendarView: (calendarRange: ICalendarRangeProps) => void
};

export default function CanvasRoadmapMainBar(props: ICanvasRoadmapMainBarProps) {
  //Redux States
  const dispatch = useAppDispatch();
  // const version = useAppSelector((state) => state.systemBar.version);
  // const boardDrawerScreenWide = useAppSelector((state) => state.boardDrawer.screenWide);
  //Redux States

  const defaultMonths = 3;
  const defaultStartDate = dayjs();
  const defaultEndDate = dayjs().add(defaultMonths - 1, 'month');

  const [calendarStartDate, setCalendarStartDate] = React.useState<Dayjs | undefined>(defaultStartDate);
  const [calendarEndDate, setCalendarEndDate] = React.useState<Dayjs | undefined>(defaultEndDate);

  const { control } =
    useForm({
      mode: 'all',
      defaultValues: {
        calendarStartDate: defaultStartDate,
        calendarEndDate: defaultEndDate
      },
    });

  // const [versions, setVersions] = useState<ASDtos.VersionReadDto[]>([]);
  // const [versionSelected, setVersionSelected] = useState<number>(0);
  // const [showNewVersionForm, setShowNewVersionForm] = useState<boolean>(false);

  const [calendarView, setCalendarView] = useState<number>(defaultMonths);
  const [rangeErrorMessage, setRangeErrorMessage] = useState<string>("");

  //Handles
  // function handleChangeVersion(event: SelectChangeEvent<number>) {
  //   setVersionSelected(Number(event.target.value));
  //   props.onChangeVersions([Number(event.target.value)]);
  // }

  function getCalendarRange(pStartDate: Dayjs | undefined, pEndDate: Dayjs | undefined) {
    return { startDate: pStartDate, endDate: pEndDate } as ICalendarRangeProps;
  }

  function handleChangeCalendarView(calendarViewParam: number, pStartDate: Dayjs | undefined, pEndDate: Dayjs | undefined) {
    setCalendarView(calendarViewParam);

    //From start date calculate end date
    var resultEndDate = pEndDate;
    var resultStartDate = pStartDate;
    switch (calendarViewParam) {
      case 1:
        resultEndDate = resultStartDate?.add(0, 'month')
        break;
      case 3:
        resultEndDate = resultStartDate?.add(2, 'month')
        break;
      case 6:
        resultEndDate = resultStartDate?.add(5, 'month');
        break;
    }

    // console.log("switch calendarViewParam: ", calendarViewParam);
    // console.log("switch resultStartDate: ", resultStartDate);
    // console.log("switch resultEndDate: ", resultEndDate);
    var refreshParent = true;
    if (DateUtils.toStringYYYYMMDD(resultStartDate?.toDate()) > DateUtils.toStringYYYYMMDD(resultEndDate?.toDate())) {
      setRangeErrorMessage("'Start Month' must be less or equal to 'End Month'.");
      refreshParent = false;
    }

    setCalendarStartDate(resultStartDate);
    setCalendarEndDate(resultEndDate);

    if (refreshParent) {
      setRangeErrorMessage("");
      const resultCalRange = getCalendarRange(resultStartDate, resultEndDate);
      // console.log("switch calendarRange: ", resultCalRange);
      props.onChangeCalendarView(resultCalRange);
    }
  }
  // OnLoad
  // function GetVersionElements(versions: ASDtos.VersionReadDto[], level: number) {
  //   let returnValues: any = [];
  //   versions.map((v: ASDtos.VersionReadDto) => {
  //     const spacing = Array.from(Array(4 * level), () => '\u00A0');

  //     returnValues.push(
  //       <MenuItem key={v.id} value={v.id}>{spacing}{v.name}</MenuItem>
  //     );

  //     if (v.childs.length > 0) {
  //       returnValues.push(...GetVersionElements(v.childs, level + 1));
  //     }
  //   });
  //   return returnValues;
  // }

  // OnRender
  return (
    <MainBarDrawer leftPadding={70} topPadding={10}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {/* <Box sx={{ p: 1 }}>
              <FormControl >
                <InputLabel sx={{ height: 30 }} id="version-select-label">Version</InputLabel>
                <Select
                  sx={{
                    height: 30,
                    width: 300
                  }}
                  labelId="version-select-label"
                  id="version-select"
                  value={versionSelected}
                  label="Version"
                  onChange={(event: any) => {
                    if (event.target.value === "") {
                      setShowNewVersionForm(true);
                    }
                    else {
                      handleChangeVersion(event)
                    }
                  }}
                >
                  <MenuItem key={0} value={0}>LIVE</MenuItem>
                  {GetVersionElements(versions, 0)}
                  <Divider />
                  <MenuItem key={""} value={""} sx={{ m: 0 }}><Button sx={{ p: 0, m: 0 }} fullWidth size='small'>New Version</Button></MenuItem>
                </Select>
              </FormControl>
            </Box> */}
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Box>
            <Controller
              control={control}
              name="calendarStartDate"
              render={({ field }) => {
                return (
                  <DatePicker
                    sx={{ p: 0, m: 0 /*height: "30px"*/ }}
                    views={['year', 'month']}
                    value={calendarStartDate}
                    maxDate={calendarEndDate}
                    inputRef={field.ref}
                    onChange={(date) => {
                      if (date && date.isValid()) {
                        handleChangeCalendarView(calendarView, date, calendarEndDate);
                        field.onChange(date);
                      }
                    }}
                    slotProps={{
                      layout: {
                        sx: {
                          paddingTop: 0, paddingBottom: 0,
                        },
                      },
                      textField: {
                        fullWidth: false,
                        autoFocus: true,
                        margin: "dense",
                        variant: 'outlined',
                        size: 'small',
                      },
                      field: {
                        clearable: false,
                      },
                    }}
                  />
                );
              }}
            />
          </Box>
          <Box sx={{ p: 0.4, border: 1, borderColor: "lightgray" }}>
            <IconButton
              sx={{ p: 0.5, m: 0, backgroundColor: (calendarView === 1) ? "#D4DBF5" : "#FFFFFF", ":hover": { backgroundColor: "#D4DBF5" } }}
              onClick={() => { handleChangeCalendarView(1, calendarStartDate, calendarEndDate) }}
            >
              <Filter1Icon />
            </IconButton>
            <IconButton
              sx={{ p: 0.5, m: 0, backgroundColor: (calendarView === 3) ? "#D4DBF5" : "#FFFFFF", ":hover": { backgroundColor: "#D4DBF5" } }}
              onClick={() => { handleChangeCalendarView(3, calendarStartDate, calendarEndDate) }}
            >
              <Filter3Icon />
            </IconButton>
            <IconButton
              sx={{ p: 0.5, m: 0, backgroundColor: (calendarView === 6) ? "#D4DBF5" : "#FFFFFF", ":hover": { backgroundColor: "#D4DBF5" } }}
              onClick={() => { handleChangeCalendarView(6, calendarStartDate, calendarEndDate) }}
            >
              <Filter6Icon />
            </IconButton>
            <IconButton
              sx={{ p: 0.5, m: 0, backgroundColor: (calendarView === 12) ? "#D4DBF5" : "#FFFFFF", ":hover": { backgroundColor: "#D4DBF5" } }}
              onClick={() => { handleChangeCalendarView(12, calendarStartDate, calendarEndDate) }}
            >
              <CalendarTodayIcon />
            </IconButton>
          </Box>
          <Box>
            <Controller
              control={control}
              name="calendarEndDate"
              render={({ field }) => {
                return (
                  <DatePicker
                    sx={{ p: 0, m: 0 }}
                    views={['year', 'month']}
                    //format="MM-YYYY"
                    value={calendarEndDate}
                    minDate={calendarStartDate}
                    inputRef={field.ref}
                    disabled={calendarView !== 12}
                    onChange={(date) => {
                      if (date && date.isValid()) {
                        handleChangeCalendarView(calendarView, calendarStartDate, date);
                        field.onChange(date);
                      }
                    }}
                    slotProps={{
                      layout: {
                        sx: {
                          paddingTop: 0,
                          paddingBottom: 0,
                        },
                      },
                      textField: {
                        fullWidth: true,
                        autoFocus: true,
                        margin: "dense",
                        variant: 'outlined',
                        size: 'small',
                      },
                      field: {
                        clearable: false,
                      },
                    }}
                  />
                );
              }}
            />
          </Box>
          <Box>
            <InputLabel sx={{ p: 1 }} error>{rangeErrorMessage}</InputLabel>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end" >
          <SelectorTeams onChangeTeams={(teamsSelected) => { props.onChangeTeams(teamsSelected); }} />
        </Stack>
      </Stack>
      {/* Create New VERSION Dialog
      <Dialog fullScreen={false} open={showNewVersionForm} onClose={() => setShowNewVersionForm(false)}>
        <DialogTitle>New Version</DialogTitle>
        <DialogContent sx={{ p: 1, maxWidth: "500px", maxHeight: "500px" }}>
          <FormNewVersion
            formIsDone={(newVersion) => {
              setVersions([...versions, newVersion]);
              setShowNewVersionForm(false);
            }}
          />
        </DialogContent>
      </Dialog> */}
    </MainBarDrawer>
  );
}