import React, { useEffect, useState } from 'react'; // let's also import Component
import { DndContext } from '@dnd-kit/core';

import { ASGateway, ASDtos } from 'api/AppServices';

import useNotification, { INotification } from 'hooks/NotificationHooks';
import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';
import { setTaskIdIsDragging, setPackageIdDeployed } from 'reducers/boardDrawerReducer';

import { TypeMapper } from 'app/TypeMapper';

import AgileBoardColumn from 'components/AgileBoards/AgileBoardColumnDragContainer';

import {
  Stack,
  Box,
} from '@mui/material';

import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';


type IBoardViewPackageProps = {
  children?: any,
  boardId: number,
  boardViewId: number,
  maxHeightContainer: number,
  setupBoardMode: boolean,
};

export default function BoardViewPackage(props: IBoardViewPackageProps) {
  const mapper = new TypeMapper();
  //Redux States
  const dispatch = useAppDispatch();
  const teams = useAppSelector((state) => state.systemBar.teams);
  //Redux States
  const notification = useNotification(); //Miliseconds

  const [columns, setColumns] = useState<any[]>([]);
  const [packages, setPackages] = useState<ASDtos.PackageReadDto[]>([]);

  //Handles
  function handleDragStart(event: any) {
    //console.log("handleDragStart(event): ", event);
    if (event.over) { //&& event.over.id === 'droppable'
      //var msg = "Card ID: " + event.active.id + " -> Dropped on Column: " + event.over.id;
    }
  }
  function handleDragMove(event: any) {
    //console.log("handleDragMove(event): ", event);
    if (event.active) {
      dispatch(setTaskIdIsDragging(event.active.id))
    }
  }
  function handleDragOver(event: any) {
    //console.log("handleDragOver(event): ", event);
    dispatch(setTaskIdIsDragging(0))
  }
  function handleDragEnd(event: any) {
    //console.log("handleDragEnd(event): ", event);
    //console.log("event.delta): ", event.delta);

    if (event.over) {
      const transitionToColumnId = event.over.id;
      const packId = event.active.id;
      //console.log("transitionToColumnId: ", transitionToColumnId);
      //console.log("packId: ", packId);

      //Get Pack that was moved
      var movedPack = packages.find((pack) => {
        return pack.id === packId;
      })!;

      if (transitionToColumnId === -2) {
        //console.log("movedPack to PROD: ", movedPack);
        //Sending Pack to PROD
        //Check if it is in all environments (Environments = allColumns - (Package + PROD))
        if (movedPack.environments.length < (columns.length - 2)) {
          notification.addNotification({
            severity: "warning",
            message: "You are trying to deploy pack to PROD without deploying in all environments. Deploy on all environments to consolidate.",
          } as INotification);
        }
        else {
          notification.addNotification({
            severity: "info",
            message: `Package #${packId} is being Released to PROD`,
          } as INotification);

          //Update Database
          ASGateway.Packages.Release(packId)
            .then((pack: ASDtos.PackageReadDto) => {
              notification.addNotification({
                severity: "success",
                message: `CONGRATS YOU HAVE JUST DEPLOYED PACKAGE #${pack.id} TO PROD`,
              } as INotification);

              //UPDATE UI
              setPackages([...packages.filter((packFind) => packFind.id !== packId)]);
              dispatch(setPackageIdDeployed(pack.id));
            })
            .catch((e: any) => {
              notification.addNotification({
                severity: "error",
                message: "System could not save your changes. Please refresh and try again.",
              } as INotification);
              console.error(e);
            });
        }
      }

      if (transitionToColumnId > 0) {
        //=== Update Locally before saving to Database ===
        //Get DESTINY Column from which pack was moved INTO
        var currentBoardColumns = [...columns];
        var envDestinyPack = currentBoardColumns.find((column) => {
          return column.id === transitionToColumnId;
        })!;

        //Add Environment to the Pack
        //Check if it is already added
        if (movedPack.environments === null) {
          movedPack.environments = [];
        }

        if (!movedPack.environments.some((env: ASDtos.EnvironmentReadDto) => env.id === envDestinyPack.id)) {
          movedPack.environments = [...movedPack.environments, envDestinyPack as ASDtos.EnvironmentReadDto]
        }

        //Save UI Result
        setColumns(currentBoardColumns);

        //Update Database
        ASGateway.Packages.AddEnvironment(
          packId,
          envDestinyPack.id
        )
          .then((pack: ASDtos.PackageReadDto) => {
            dispatch(setPackageIdDeployed(pack.id));
          })
          .catch((e: any) => {
            notification.addNotification({
              severity: "error",
              message: "System could not save your changes. Please refresh and try again.",
            } as INotification);
            console.error(e);
          });
      }

      //console.log("packages: ", packages);
    }
  }

  async function handleLoadPackages() {
    //Call Service
    await ASGateway.Packages.GetByStatus(ASDtos.PackageStatusDto.Unreleased)
      .then((data: ASDtos.PackageReadDto[]) => {
        //console.log('GetPackages: ', data);
        setPackages(data)
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  function handleRefreshPackages() {
    handleLoadPackages()
      .catch((e: any) => {
        console.error(e);
      });
  }

  // OnLoad
  useEffect(() => {

    ASGateway.Environments.GetAll(teams[0])
      .then((data: ASDtos.EnvironmentReadDto[]) => {
        //console.log('GetAllEnvironments: ', data);
        //setEnvironments(data)
        var columnsTmp = [{ id: -1, name: "Packages" }] as any[];
        data.map((env: ASDtos.EnvironmentReadDto, index) => {
          columnsTmp = [...columnsTmp, { id: env.id, name: env.name }]
        });
        columnsTmp = [...columnsTmp, { id: -2, name: "Prod" }]
        setColumns(columnsTmp);

        //Call Service
        handleLoadPackages()
          .catch((e: any) => {
            console.error(e);
          });

      })
      .catch((e: any) => {
        console.error(e);
      });


  }, [props]);

  // OnRender
  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <Box width="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
          sx={{
            //mt: 1,
            ml: 2,
            mr: 2,
            height: "95%",
          }}
        >
          {/* Columns */}
          {columns.map((column, indexCol) => {
            return (
              <AgileBoardColumn
                key={column.id}
                boardId={props.boardId}
                viewId={props.boardViewId}
                columnId={column.id}
                columnName={column.name}
                columnBlocked={true}
                columnIsFirst={indexCol === 1} //Zero Based
                columnIsLast={indexCol === (columns.length - 2)} //Zero Based
                columnMinHeight={props.maxHeightContainer - 100}
                cards={mapper.mappPackagesToCards(
                  packages.map((pack, indexPack) => {
                    //console.log("mappPackagesToCards pack: ", pack);
                    //When is the dragabble card of PACK pass the Pack
                    if (indexCol === 0) {
                      return pack;
                    }

                    //When is NOT the dragabble card of PACK a dummy card with result 
                    //To have it not dragabble have the ID to negative number
                    var nameResult = "";
                    if (pack.environments?.some(e => e.id === column.id)) {
                      nameResult = "DEPLOYED";
                    }

                    return { id: (pack.id * -1), name: nameResult } as ASDtos.PackageReadDto;
                  }),

                )}
                setupBoardMode={props.setupBoardMode}
                updateParent={handleRefreshPackages}
              />
            );
          })}
        </Stack>
      </Box>
    </DndContext>
  );
}