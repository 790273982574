import React, { useEffect, useState } from 'react'; // let's also import Component

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';

import { ASGateway, ASDtos } from 'api/AppServices';

import FormNewBoard from 'components/Forms/FormNewBoard';

import {
  Stack,
  Paper,
  Box,
  Divider,
  Select,
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';

import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import IconSvg from 'components/IconSvg';

type IBoardViewsMenuProps = {
  openMenu: boolean,
  onBoardChange(
    selectedBoard: number, 
    selectedBoardType: ASDtos.BoardTypeDto, 
    selectedBoardView: number, 
    selectedBoardViewType: ASDtos.BoardViewTypeDto
  ): void;
};

export default function BoardViewsMenu(props: IBoardViewsMenuProps) {
  //Redux States
  const dispatch = useAppDispatch();
  //Redux States

  const [boardId, setBoardId] = useState<number | "">("");
  const [boardType, setBoardType] = useState<ASDtos.BoardTypeDto>(ASDtos.BoardTypeDto.Scrum);
  const [boards, setBoards] = useState([] as ASDtos.BoardReadDto[]);

  const [boardViewId, setBoardViewId] = useState<number | "">("");
  const [boardViewType, setBoardViewType] = useState<ASDtos.BoardViewTypeDto>(ASDtos.BoardViewTypeDto.Backlog);
  const [boardViews, setBoardViews] = useState([] as ASDtos.BoardViewReadDto[]);

  const [showNewBoardForm, setShowNewBoardForm] = useState(false);

  //Handles
  async function handleLoadBoardViews(boardIdToLoad: number, boardTypeToLoad: ASDtos.BoardTypeDto) {
    //Call Service
    //console.log("handleLoadBoardViews boardId:", boardId);
    await ASGateway.BoardViews.GetAll(Number(boardIdToLoad))
      .then((data: ASDtos.BoardViewReadDto[]) => {
        //console.log('GetAllBoardViews: ', data);
        var defaultBoardView = data.find((boardView: ASDtos.BoardViewReadDto) => {
          return boardView.type === ASDtos.BoardViewTypeDto.Backlog; //Backlog Type
        });
        if (!defaultBoardView) {
          defaultBoardView = data[0]; //when not found Choose the First one
        }

        setBoardViews(data);
        setBoardViewId(defaultBoardView.id);
        setBoardViewType(defaultBoardView.type);
        props.onBoardChange(Number(boardIdToLoad), boardTypeToLoad, Number(defaultBoardView.id), defaultBoardView.type);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }


  function handleLoadBoards() {

    //Call Service
    ASGateway.Boards.GetAll()
      .then((data: ASDtos.BoardReadDto[]) => {
        //console.log('GetAllBoards: ', data);
        setBoards(data);
        setBoardId(data[0].id);
        setBoardType(data[0].type);
        handleLoadBoardViews(data[0].id, data[0].type);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  // OnLoad
  useEffect(() => {
    //console.log('useEffect INI MenuViews boardId: ', boardId);
    if (boardId === "") {
      handleLoadBoards();
    }
    //console.log('useEffect END MenuViews boardId: ', boardId);
  }, []);

  // OnRender
  return (
    <>
      {props.openMenu &&
        <Paper
          sx={{
            width: 200,
            minWidth: 200,
            maxWidth: 200,
            maxHeight: 400,
            overflow: 'auto',
            backgroundColor: "#f0f7ff" //#e8f3fc #f2f9ff #edf6ff #f0f7ff
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={0}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Box sx={{ p: 1, width: "100%", }}>
                <FormControl variant="standard" fullWidth>
                  <Select
                    fullWidth
                    sx={{
                      width: "100%",
                      height: 30,
                      textAlign: "left"
                    }}
                    id="boards"
                    name="boards"
                    labelId="boards"
                    value={boardId}
                    onChange={(event: any) => {
                      var optionSelected = Number(event.target.value);
                      if (optionSelected === 0) {
                        setShowNewBoardForm(true);
                      }
                      else {
                        var board = boards.find((board: ASDtos.BoardReadDto) => {
                          return board.id === optionSelected;
                        });
                        if (!board){
                          board = boards[0];
                        }
                
                        setBoardId(board.id);
                        setBoardType(board.type);
                        handleLoadBoardViews(board.id, board.type)
                      }
                    }}
                  >
                    {boards.map((board: ASDtos.BoardReadDto) => {
                      return (
                        <MenuItem key={board.id.toString()} value={board.id.toString()}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={1}
                          >
                            <ListItemText primary={board.name} />
                            {board.masterBoard &&
                              <ListItemIcon>
                                <StarPurple500Icon fontSize='small' />
                              </ListItemIcon>
                            }
                          </Stack>
                        </MenuItem>
                      )
                    })}
                    <Divider />
                    <MenuItem key={""} value={""}>New Board</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Stack>
            <Divider />
            {boardViews.map((boardView: ASDtos.BoardViewReadDto) => {
              return (
                <MenuItem
                  key={boardView.id}
                  value={boardView.id}
                  selected={boardViewId === boardView.id}
                  onClick={(event) => {
                    setBoardViewId(boardView.id);
                    setBoardViewType(boardView.type);
                    props.onBoardChange(Number(boardId), boardType, Number(boardView.id), boardView.type);
                  }}
                >
                  <ListItemIcon>
                    {boardView.type === ASDtos.BoardViewTypeDto.Backlog &&
                      <IconSvg icon='Backlog' />
                    }
                    {boardView.type === ASDtos.BoardViewTypeDto.Sprint &&
                      <IconSvg icon='Board' />
                    }
                    {boardView.type === ASDtos.BoardViewTypeDto.Kanban &&
                      <IconSvg icon='ViewKanban' />
                    }
                    {boardView.type === ASDtos.BoardViewTypeDto.Package &&
                      <IconSvg icon='Package' />
                    }
                  </ListItemIcon>
                  <ListItemText primary={boardView.name} style={{ textAlign: "left" }} />
                </MenuItem>
              )
            })}
            <Divider />
            {[0].map((x: number) => {
              const packId = Math.max(...boardViews.map(o => o.id))+1;
              const packViewType = ASDtos.BoardViewTypeDto.Package;
              const packViewName = "Package";
              return (
                <MenuItem
                  key={packId}
                  value={packId}
                  selected={boardViewId === packId}
                  onClick={(event) => {
                    setBoardViewId(packId);
                    setBoardViewType(packViewType);
                    props.onBoardChange(Number(boardId), boardType, packId, packViewType);
                  }}
                >
                  <ListItemIcon>
                    <IconSvg icon='Package' />
                  </ListItemIcon>
                  <ListItemText primary={packViewName} style={{ textAlign: "left" }} />
                </MenuItem>
              )
            })}

          </Stack>
        </Paper>
      }
      {/* Create New BOARD Dialog */}
      <Dialog fullScreen={false} open={showNewBoardForm} onClose={() => setShowNewBoardForm(false)}>
        <DialogTitle>New Board</DialogTitle>
        <DialogContent sx={{ p: 1, maxWidth: "500px", maxHeight: "500px" }}>
          <FormNewBoard
            formIsDone={(newBoard: ASDtos.BoardReadDto) => {
              setBoards([...boards, newBoard]);
              setBoardId(newBoard.id);
              setBoardType(newBoard.type);
              setShowNewBoardForm(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}