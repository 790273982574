import React, { useEffect, useState, useCallback, useRef } from 'react'; // let's also import Component

import { ASGateway, ASDtos } from 'api/AppServices';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';
import { setScreenWideBoardDrawer } from 'reducers/boardDrawerReducer';
import { ScreenWideEntry } from 'app/AppValues';

import BoardViewSprint from 'components/AgileBoards/BoardViews/BoardViewSprint';
import BoardViewBacklog from 'components/AgileBoards/BoardViews/BoardViewBacklog';
import BoardViewPackage from 'components/AgileBoards/BoardViews/BoardViewPackage';
import BoardViewKanban from 'components/AgileBoards/BoardViews/BoardViewKanban';
import BoardViewSafe from 'components/AgileBoards/BoardViews/BoardViewSafe';
import BoardViewsMenu from 'components/AgileBoards/BoardViews/BoardViewsMenu';

import FormNewBoardColumn from 'components/Forms/FormNewBoardColumn';

import {
  Stack,
  Drawer,
  Box,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddBoxIcon from '@mui/icons-material/AddBox';

import IconSvg from 'components/IconSvg';

export default function AgileBoardDrawer() {
  //Redux States
  const dispatch = useAppDispatch();
  const teams = useAppSelector((state) => state.systemBar.teams);
  const openBoardsScreenWide = useAppSelector((state) => state.boardDrawer.screenWide);
  const openDetailsScreenWide = useAppSelector((state) => state.detailDrawer.screenWide);
  //Redux States

  const [drawerWidth, setDrawerWidth] = useState<string>("100%");
  const [drawerHeight, setDrawerHeight] = useState<string>("50%");
  const [maxHeightDrawer, setMaxHeightDrawer] = useState<number>(0);

  const [menuBoardsOpen, setMenuBoardsOpen] = useState<boolean>(true);
  const [selectedBoardId, setSelectedBoardId] = useState<number>(0);
  const [selectedBoardType, setSelectedBoardType] = useState<ASDtos.BoardTypeDto>(ASDtos.BoardTypeDto.Scrum);
  const [selectedBoardViewId, setSelectedBoardViewId] = useState<number>(0);
  const [selectedBoardViewType, setSelectedBoardViewType] = useState<ASDtos.BoardViewTypeDto>();

  const [showNewColumnForm, setShowNewColumnForm] = useState<boolean>(false);
  const [setupBoardMode, setSetupBoardMode] = useState<boolean>(false);

  //Setup Resize Observer for Drawer
  if (window.ResizeObserver) {
    //const divElem = document.querySelector('body > div');
    const divElem = document.getElementById('agile-board-drawer');

    const resizeObserver = new ResizeObserver(entries => {
      //console.log('Size changed entries: ', entries);
      for (let entry of entries) {
        var entryTarget = entry.target;
        var entryTargetFirstChild = entryTarget.firstElementChild!;
        var size = entryTargetFirstChild.clientHeight;
        //console.log('Size changed entry: ', entry);
        //console.log('Size changed size: ', size);
        setMaxHeightDrawer(size);
      }
    });

    if (divElem) {
      //console.log('Observer Active');
      resizeObserver.observe(divElem);
    }
  }

  function handleDrawerCollapse() {
    if (openBoardsScreenWide === ScreenWideEntry.FULL) {
      dispatch(setScreenWideBoardDrawer(ScreenWideEntry.THREEQUARTERS));
    }
    if (openBoardsScreenWide === ScreenWideEntry.THREEQUARTERS) {
      dispatch(setScreenWideBoardDrawer(ScreenWideEntry.HALF));
    }
    if (openBoardsScreenWide === ScreenWideEntry.HALF) {
      dispatch(setScreenWideBoardDrawer(ScreenWideEntry.QUARTER));
    }
    if (openBoardsScreenWide === ScreenWideEntry.QUARTER) {
      dispatch(setScreenWideBoardDrawer(ScreenWideEntry.HIDE));
    }
  }
  function handleDrawerExpand() {
    if (openBoardsScreenWide === ScreenWideEntry.HIDE) {
      dispatch(setScreenWideBoardDrawer(ScreenWideEntry.QUARTER));
    }
    if (openBoardsScreenWide === ScreenWideEntry.QUARTER) {
      dispatch(setScreenWideBoardDrawer(ScreenWideEntry.HALF));
    }
    if (openBoardsScreenWide === ScreenWideEntry.HALF) {
      dispatch(setScreenWideBoardDrawer(ScreenWideEntry.THREEQUARTERS));
    }
    if (openBoardsScreenWide === ScreenWideEntry.THREEQUARTERS) {
      dispatch(setScreenWideBoardDrawer(ScreenWideEntry.FULL));
    }
  }

  function handleMenuToggle() {
    setMenuBoardsOpen(x => !x);
  }

  //Whenever BoardScreenWide Value Changes Recalculate percentage for HEIGHT
  useEffect(() => {
    //Adapt Height
    switch (openBoardsScreenWide) {
      case ScreenWideEntry.HIDE:
        setDrawerHeight("0%");
        break;
      case ScreenWideEntry.QUARTER:
        setDrawerHeight("25%");
        break;
      case ScreenWideEntry.HALF:
        setDrawerHeight("50%");
        break;
      case ScreenWideEntry.THREEQUARTERS:
        setDrawerHeight("75%");
        break;
      case ScreenWideEntry.FULL:
        setDrawerHeight("100%");
        break;
    }
  }, [openBoardsScreenWide]);

  // //Whenever DetailScreenWide Value Changes Recalculate percentage for WIDTH
  // useEffect(() => {
  //   if (openBoardsScreenWide !== ScreenWideEntry.HIDE) {
  //     //Adapt Boards Width Inverting Details Width
  //     switch (openDetailsScreenWide) {
  //       case ScreenWideEntry.HIDE:
  //         setDrawerWidth("100%");
  //         break;
  //       case ScreenWideEntry.QUARTER:
  //         setDrawerWidth("75%");
  //         break;
  //       case ScreenWideEntry.HALF:
  //         setDrawerWidth("50%");
  //         break;
  //       case ScreenWideEntry.THREEQUARTERS:
  //         setDrawerWidth("25%");
  //         break;
  //       case ScreenWideEntry.FULL:
  //         setDrawerWidth("0%");
  //         break;
  //     }
  //   }
  // }, [openDetailsScreenWide]);

  // OnRender
  return (
    <>
      <Drawer
        id="agile-board-drawer"
        sx={{
          width: drawerWidth,
          height: drawerHeight,
          flexShrink: 1,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            height: drawerHeight,
            overflowY: 'hidden',
            overflowX: 'hidden'
          },
        }}
        variant="persistent"
        anchor="bottom"
        open={openBoardsScreenWide !== ScreenWideEntry.HIDE}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            m: 1
          }}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Box>
              <IconButton sx={{ ":hover": { backgroundColor: "#D4DBF5" } }}
                onClick={handleDrawerExpand}
              >
                <KeyboardArrowUpIcon />
              </IconButton>
              <IconButton sx={{ ":hover": { backgroundColor: "#D4DBF5" } }}
                onClick={handleDrawerCollapse}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
              <IconButton sx={{ backgroundColor: (menuBoardsOpen) ? "#D4DBF5" : "#FFFFFF", ":hover": { backgroundColor: "#D4DBF5" } }}
                onClick={handleMenuToggle}
              >
                <IconSvg color='info' icon='MenuOpen' />
              </IconButton>
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={0.5}
          >
            {setupBoardMode &&
              <Box>
                <Button
                  sx={{
                    p: 1,
                    m: 0,
                    color: 'text.secondary',
                    ":hover": { backgroundColor: "#D4DBF5" }
                  }}
                  endIcon={<AddBoxIcon />}
                  onClick={() => setShowNewColumnForm(true)}
                >
                  Column
                </Button>
              </Box>
            }
            <IconButton
              sx={{
                ":hover": { backgroundColor: "#D4DBF5" }
              }}
              onClick={() => {
                //LoadBoards();
                return;
              }}
            >
              <AutorenewIcon />
            </IconButton>

            <IconButton
              sx={{
                backgroundColor: (setupBoardMode) ? "#D4DBF5" : "#FFFFFF",
                ":hover": { backgroundColor: "#D4DBF5" }
              }}
              onClick={() => setSetupBoardMode(current => !current)}
            >
              <SettingsApplicationsIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={1}
        >
          <Box />
          {/* Board Menu */}
          <BoardViewsMenu
            openMenu={menuBoardsOpen}
            onBoardChange={(
              selectedBoard: number,
              selectedBoardType: ASDtos.BoardTypeDto,
              selectedBoardView: number,
              selectedBoardViewType: ASDtos.BoardViewTypeDto
            ) => {
              //console.log("onBoardChange selectedBoard: ", selectedBoard);
              //console.log("onBoardChange selectedBoardView: ", selectedBoardView);
              //console.log("onBoardChange selectedBoardViewType: ", selectedBoardViewType);
              setSelectedBoardId(selectedBoard);
              setSelectedBoardType(selectedBoardType);
              setSelectedBoardViewId(selectedBoardView);
              setSelectedBoardViewType(selectedBoardViewType);
            }}
          />
          {/* Board Menu */}

          {selectedBoardViewType === ASDtos.BoardViewTypeDto.Backlog &&
            <BoardViewBacklog boardId={selectedBoardId} boardType={selectedBoardType} boardViewId={selectedBoardViewId} setupBoardMode={setupBoardMode} maxHeightContainer={maxHeightDrawer} />
          }
          {selectedBoardViewType === ASDtos.BoardViewTypeDto.Sprint &&
            <BoardViewSprint boardId={selectedBoardId} boardViewId={selectedBoardViewId} maxHeightContainer={maxHeightDrawer} setupBoardMode={setupBoardMode} />
          }
          {selectedBoardViewType === ASDtos.BoardViewTypeDto.Kanban &&
            <BoardViewKanban boardId={selectedBoardId} boardViewId={selectedBoardViewId} maxHeightContainer={maxHeightDrawer} setupBoardMode={setupBoardMode} />
          }
          {selectedBoardViewId && selectedBoardViewType === ASDtos.BoardViewTypeDto.Package &&
            <BoardViewPackage boardId={selectedBoardId} boardViewId={selectedBoardViewId} maxHeightContainer={maxHeightDrawer} setupBoardMode={setupBoardMode} />
          }
          {/* {selectedBoardViewId && selectedBoardViewType === ASDtos.BoardViewTypeDto.SAFE &&
              <BoardViewSafe boardId={selectedBoardId} boardViewId={selectedBoardViewId} maxHeightContainer={maxHeightDrawer} setupBoardMode={setupBoardMode} />
            } */}
        </Stack>
      </Drawer>
      {/* Create New COLUMN Dialog */}
      <Dialog fullScreen={false} open={showNewColumnForm} onClose={() => setShowNewColumnForm(false)}>
        <DialogTitle>New Board Column</DialogTitle>
        <DialogContent sx={{ p: 1, maxWidth: "500px", maxHeight: "500px" }}>
          <FormNewBoardColumn
            boardId={Number(selectedBoardId)}
            boardViewId={Number(selectedBoardViewId)}
            formIsDone={(newBoardColumn) => {
              setShowNewColumnForm(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}