import React, { useEffect, useState } from 'react'; // let's also import Component
import { useDroppable } from '@dnd-kit/core';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';
import { ASGateway, ASDtos } from 'api/AppServices';

import { IAgileCard } from 'app/AppTypes';
import { TypeMapper } from 'app/TypeMapper';
import { CardTypeEntry, FormSprintModeEntry } from 'app/AppValues';

import AgileCard from 'components/AgileBoards/AgileCard';
import FormSprint from 'components/Forms/FormSprint';

import {
  Stack,
  Paper,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogContent,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DateUtils } from 'app/DateUtils';

type IAgileBoardSprintDragContainerProps = {
  children?: any,
  boardId: number,
  //  viewId: number,
  sprint: ASDtos.BoardIterationReadDto,
  //  setupBoardMode: boolean,
  updateParent: () => void;
};

export default function AgileBoardSprintDragContainer(props: IAgileBoardSprintDragContainerProps) {
  const mapper = new TypeMapper();
  //Redux States
  const dispatch = useAppDispatch();
  const taskIdIsDragging = useAppSelector((state) => state.boardDrawer.taskIdIsDragging);
  //Redux States

  const { isOver, over, node, setNodeRef } = useDroppable({ id: "s" + props.sprint.id });
  const [cards, setCards] = useState<IAgileCard[]>([]);
  const [sprintDetail, setSprintDetail] = useState<ASDtos.BoardIterationReadDto | undefined>(undefined);

  const [anchorSprintMenu, setAnchorSprintMenu] = useState<null | HTMLElement>(null);
  //const [openSprintMenu, setOpenSprintMenu] = useState<null | number>(null);
  const [openSprintMenu, setOpenSprintMenu] = useState<boolean>(false);

  const [showEditSprintForm, setShowEditSprintForm] = useState<boolean>(false);
  const [showDeleteSprintForm, setShowDeleteSprintForm] = useState<boolean>(false);
  const [showStartSprintForm, setShowStartSprintForm] = useState<boolean>(false);
  const [showCompleteSprintForm, setShowCompleteSprintForm] = useState<boolean>(false);

  //Handles
  function handleOpenSprintMenu(event: React.MouseEvent<HTMLElement>) {
    //console.log("handleOpenSprintMenu event.currentTarget: ", event.currentTarget)
    event.stopPropagation();
    event.preventDefault();
    setAnchorSprintMenu(event.currentTarget);
    setOpenSprintMenu(true);
  }
  function handleCloseSprintMenu() {
    setOpenSprintMenu(false);
  }

  function isDraggingStarted() {
    // console.log("Column isOver: ", isOver);
    // console.log("Column over: ", over);
    // console.log("Column node: ", node);
    if (taskIdIsDragging > 0) {
      return isOver;
    }
    return false;
  }

  // OnLoad
  useEffect(() => {
    //console.log("Props: ", props);
    //Load Sprint
    setSprintDetail(props.sprint);
  }, [props]);

  // OnRender
  return (
    <>
      <Accordion 
        key={"sprint-wrapper-" + sprintDetail?.id}
        disableGutters 
        sx ={{
          backgroundColor: "#F0F0F0",
          border: isDraggingStarted() ? "2px dashed green" : undefined,
        }}
        ref={setNodeRef}
      >
        <AccordionSummary id="backlog-sprints-summary"
        // sx={{flexDirection: 'row-reverse', verticalAlign: 'top', alignItems: 'stretch', margin: '12px' }} expandIcon={<ExpandMoreIcon />}
        >
          <Box width="100%">
            <Stack id="backlog-sprints-summary-bottom-stack" direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                  <Box><b>{sprintDetail?.name}</b></Box>
                  <Box>{(sprintDetail?.tasks) ? sprintDetail?.tasks.length : 0} Tasks</Box>
                  {sprintDetail?.status === ASDtos.BoardIterationStatusDto.Active &&
                    <Box><Chip label={ASDtos.BoardIterationStatusDto[sprintDetail?.status]} size='small' /></Box>
                  }
                </Stack>
                <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
                  {sprintDetail?.status === ASDtos.BoardIterationStatusDto.Active &&
                    <>
                      <Chip variant="filled" label={sprintDetail?.tasksCounter.todo} color='default' size='small' />
                      <Chip variant="filled" label={sprintDetail?.tasksCounter.wip} color='info' size='small' />
                      <Chip variant="filled" label={sprintDetail?.tasksCounter.done} color='success' size='small' />
                    </>
                  }
                  {sprintDetail?.status === ASDtos.BoardIterationStatusDto.Future &&
                    <Button variant="outlined"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        //setSprintDetail(sprint);
                        setShowStartSprintForm(true);
                      }}
                    >
                      start sprint
                    </Button>
                  }
                  <IconButton
                    aria-haspopup="true"
                    onClick={(e) => { handleOpenSprintMenu(e); }}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  {/* Sprint Menu */}
                  <Menu
                    id={"sprint-menu-" + sprintDetail?.id}
                    key={"sprint-menu-" + sprintDetail?.id}
                    anchorEl={anchorSprintMenu}
                    open={openSprintMenu}
                    onClose={(e) => {
                      //e.stopPropagation();
                      //e.preventDefault();
                      handleCloseSprintMenu();
                    }}
                  >
                    <MenuItem
                      id={"sprint-menu-edit-sprint-" + sprintDetail?.id}
                      key={"sprint-menu-edit-sprint-" + sprintDetail?.id}
                      onClick={(e) => {
                        //setSprintDetail(sprint);
                        e.stopPropagation();
                        e.preventDefault();
                        setOpenSprintMenu(false);
                        setShowEditSprintForm(true);
                      }}
                    >
                      Edit Sprint
                    </MenuItem>
                    {sprintDetail?.status === ASDtos.BoardIterationStatusDto.Active &&
                      <MenuItem
                        id={"sprint-menu-complete-sprint-" + sprintDetail?.id}
                        key={"sprint-menu-complete-sprint-" + sprintDetail?.id}
                        onClick={(e) => {
                          //setSprintDetail(sprint);
                          e.stopPropagation();
                          e.preventDefault();
                          setOpenSprintMenu(false);
                          setShowCompleteSprintForm(true);
                        }}
                      >
                        Complete Sprint
                      </MenuItem>
                    }
                    {sprintDetail?.status === ASDtos.BoardIterationStatusDto.Future &&
                      <MenuItem
                        id={"sprint-menu-delete-sprint-" + sprintDetail?.id}
                        key={"sprint-menu-delete-sprint-" + sprintDetail?.id}
                        onClick={(e) => {
                          //setSprintDetail(sprint);
                          e.stopPropagation();
                          e.preventDefault();
                          setOpenSprintMenu(false);
                          setShowDeleteSprintForm(true);
                        }}
                      >
                        Delete Sprint
                      </MenuItem>
                    }
                  </Menu>
                </Stack>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0}>
                <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                  <Box>{DateUtils.toStringLocale(sprintDetail?.startDate)}</Box>
                  <Box>{DateUtils.toStringLocale(sprintDetail?.endDate)}</Box>
                </Stack>
              </Stack>
              <Stack id="backlog-sprints-summary-bottom-stack-goals" direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0} style={{ margin: 0 }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                  <Box>{sprintDetail?.goals}</Box>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
            <Paper sx={{ backgroundColor: "#F0F0F0" }}>
              <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                {(!sprintDetail?.tasks || sprintDetail?.tasks.length <= 0) &&
                  <Box sx={{ p: 1, width: "100%" }}>No Tasks Available</Box>
                }

                {sprintDetail?.tasks?.map((task, index) => {
                  //console.log("Backlog card.type: ", Number(card.type));
                  var cardToLoad = mapper.mappTasksToCards([task], CardTypeEntry.BACKLOG)[0];
                  return (
                    <AgileCard
                      key={cardToLoad.cardId.toString()}
                      card={cardToLoad}
                      isSelectable={false}
                      isFirst={index === 0}
                      isLast={index === sprintDetail?.tasks.length - 1}
                      onChangeSelect={(e) => { return }}
                    />
                  );
                })}
              </Stack>
            </Paper>
          </Stack>
        </AccordionDetails>
      </Accordion>

      {/* Edit Sprint Dialog */}
      <Dialog fullScreen={false} open={showEditSprintForm} onClose={() => setShowEditSprintForm(false)}>
        <DialogContent sx={{ p: 1, minWidth: "300px", maxWidth: "500px", }}>
          <FormSprint
            boardId={props.boardId}
            formMode={FormSprintModeEntry.EDIT}
            sprintDetail={sprintDetail}
            formIsDone={(sprintResult) => { 
              setSprintDetail(sprintResult); 
              props.updateParent(); 
              setShowEditSprintForm(false); 
            }}
          />
        </DialogContent>
      </Dialog>
      {/* Delete Sprint Dialog */}
      <Dialog fullScreen={false} open={showDeleteSprintForm} onClose={() => setShowDeleteSprintForm(false)}>
        <DialogContent sx={{ p: 1, minWidth: "300px", maxWidth: "500px", }}>
          <FormSprint
            boardId={props.boardId}
            formMode={FormSprintModeEntry.DELETE}
            sprintDetail={sprintDetail}
            formIsDone={(sprintResult) => { setSprintDetail(sprintResult); props.updateParent(); setShowDeleteSprintForm(false); }}
          />
        </DialogContent>
      </Dialog>
      {/* Start Sprint Dialog */}
      <Dialog fullScreen={false} open={showStartSprintForm} onClose={() => setShowStartSprintForm(false)}>
        <DialogContent sx={{ p: 1, minWidth: "300px", maxWidth: "500px", }}>
          <FormSprint
            boardId={props.boardId}
            formMode={FormSprintModeEntry.START}
            sprintDetail={sprintDetail}
            formIsDone={(sprintResult) => { 
              //console.log("sprintResult: ", sprintResult);
              setSprintDetail(sprintResult); 
              props.updateParent(); 
              setShowStartSprintForm(false); 
            }}
          />
        </DialogContent>
      </Dialog>
      {/* Complete Sprint Dialog */}
      <Dialog fullScreen={false} open={showCompleteSprintForm} onClose={() => setShowCompleteSprintForm(false)}>
        <DialogContent sx={{ p: 1, minWidth: "300px", maxWidth: "500px", }}>
          <FormSprint
            boardId={props.boardId}
            formMode={FormSprintModeEntry.COMPLETE}
            sprintDetail={sprintDetail}
            formIsDone={(sprintResult) => { setSprintDetail(sprintResult); props.updateParent(); setShowCompleteSprintForm(false); }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
