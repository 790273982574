import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import DOMPurify from 'isomorphic-dompurify';
import HTMLParser from 'html-react-parser';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';

import { ASGateway, ASDtos } from 'api/AppServices';

import Editor from 'components/Editor';
import FormNewDocument from 'components/Forms/FormNewDocument';
import FormAddChangeToTask from 'components/Forms/FormAddChangeToTask';
import IconSvg from "components/IconSvg";

import {
  Stack,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Fade,
  Chip,
  Badge,
  Checkbox,
  Divider,
  Button,
  Tabs,
  Tab,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Menu,
  MenuItem,
} from '@mui/material';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionIcon from '@mui/icons-material/Description';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LaunchIcon from '@mui/icons-material/Launch';

type IDetailContainerNodeProps = {
  nodeId: number
};

enum DataBlockChangesFilter {
  ALL,
  TASKSASSIGNED,
  TASKSUNASSIGNED
};

export default function DetailContainerNode(props: IDetailContainerNodeProps) {
  //Redux States
  // const dispatch = useAppDispatch();
  const version = useAppSelector((state) => state.systemBar.version);
  //Redux States

  const [nodeData, setNodeData] = useState({} as any);

  const [documents, setDocuments] = useState([] as any[]);
  const [documentSelectedIndex, setDocumentSelectedIndex] = useState(0);
  const [documentSelected, setDocumentSelected] = useState({} as any);
  const [documentSelectedData, setDocumentSelectedData] = useState(null as any[] | null);


  const [showAddChangeToTaskForm, setShowAddChangeToTaskForm] = useState(false);
  const [showNewDocumentForm, setShowNewDocumentForm] = useState(false);
  const [showEditorResult, setShowEditorResult] = useState(false);
  const [editorResult, setEditorResult] = useState({} as any);

  const [checkedUnassigned, setCheckedUnassigned] = React.useState([0]);
  const [anchorTasksAssignedToChange, setAnchorTasksAssignedToChange] = React.useState<null | HTMLElement>(null);
  const [taskIdAssignedToChange, setTaskIdAssignedToChange] = React.useState<null | number>(null);

  let haveChangesOnSave = false;

  function hasChangedStatusFromEditorBlockResult(block: any) {
    var isChanged = block.isChanged;
    var isNew = (block.apiRef === -1);

    return (isNew || isChanged);
  }

  function getAllChanges(filter: DataBlockChangesFilter): any[] {
    //console.log("dataDocuments: ", documents);
    var result: any[] = [];
    documents?.forEach((document: any, indexDoc: number) => {
      var parsedBlocks = parseDocumentBlocks(document.blocks);
      // console.log("document.blocks: ", document.blocks);
      // console.log("parsedBlocks: ", parsedBlocks);
      parsedBlocks.forEach((dataBlock: any, indexBlock: number) => {
        //console.log("getAllChanges dataBlock: ", dataBlock);
        if (dataBlock.isChanged || dataBlock.isNew) {
          switch (filter) {
            case DataBlockChangesFilter.TASKSASSIGNED:
              {
                if (dataBlock.tasks?.length > 0) {
                  result = [...result, dataBlock];
                }
                break;
              }
            case DataBlockChangesFilter.TASKSUNASSIGNED:
              {
                // console.log("getAllChanges TASKSUNASSIGNED dataBlock.tasks: ", dataBlock.tasks);
                // console.log("getAllChanges TASKSUNASSIGNED Add: ", (dataBlock.tasks === null || dataBlock.tasks.length === 0));
                // console.log("getAllChanges TASKSUNASSIGNED Cond dataBlock.tasks.lenght: ", dataBlock.tasks.length);
                if (dataBlock.tasks === null || dataBlock.tasks.length === 0) {
                  result = [...result, dataBlock];
                }
                break;
              }
            case DataBlockChangesFilter.ALL:
              {
                result = [...result, dataBlock];
                break;
              }
            default:
              {
                break;
              }
          }
        }
      });
    });
    return result;
  }

  function getStatusFromEditorBlockResult(block: any) {
    var isChanged = block.isChanged;
    var isNew = (block.apiRef === -1 || block.isNew);

    if (isNew) {
      return "New";
    }
    if (isChanged) {
      return "Changed";
    }

    return "Not Changed";
  }

  function getColorFromEditorBlockResult(block: any) {
    var isChanged = block.isChanged;
    var isNew = (block.apiRef === -1 || block.isNew);

    if (isNew) {
      return "success";
    }
    if (isChanged) {
      return "warning";
    }

    return "info";
  }

  function getTotalChangesOnDocument() {
    var documentsWithChanges = documentSelectedData?.filter((block: any) => {
      return (block.isChanged || block.isNew);
    });

    return (documentsWithChanges) ? documentsWithChanges.length : 0;
  }

  function parseDocumentBlocks(documentBlocks: any[]) {
    var parsedBlocks = [] as any[];
    if (documentBlocks && Array.isArray(documentBlocks)) {
      parsedBlocks = [...documentBlocks];
      //console.log("parseDocumentBlocks blocksToParse: ", parsedBlocks);
      parsedBlocks.forEach((block: any) => {
        //console.log("parseDocumentBlocks Parse Block: ", block);
        if (typeof block.data === 'string') { //Only Parse if it has a String to Parse
          block.data = JSON.parse(block.data);
        }
      });
    }
    //console.log("parseDocumentBlocks parsedBlocks: ", parsedBlocks);
    return parsedBlocks;
  }

  //Handles
  function handleChangesAssigned(taskId: number | null, changes: number[]) {
    if (taskId !== null) {
      //console.log("handleChangesAssigned documents: ", documents);
      var documentsUpdated = [...documents];
      //Find Document for all Changes Assigned
      documentsUpdated.forEach((document: any, index: number) => {
        document.blocks.forEach((block: any, index: number) => {
          var blockId = block.apiRef;
          var isBlockChanged = changes.some(change => change === blockId);
          if (isBlockChanged) {
            //Add task into the block
            block.tasks = [...block.tasks, taskId];
          }
        });
      });
      var documentSelectedUpdated = documents.find(document => document.Id === documentSelected.Id);

      //console.log("handleChangesAssigned documentsUpdated: ", documentsUpdated);
      setDocuments(documentsUpdated);
      setDocumentSelected(documentSelectedUpdated);
    }
    setShowAddChangeToTaskForm(false);
  }

  function handleNewDocCreated(newDocCreated: any) {
    if (newDocCreated) {
      var newDocuments = [...documents, newDocCreated];
      var newIndex = newDocuments.length - 1;

      setDocuments(newDocuments);
      setDocumentSelected(newDocuments[newIndex]);
      setDocumentSelectedIndex(newIndex);
    }
    setShowNewDocumentForm(false);
  }

  function handleDocumentSelected(event: React.SyntheticEvent<Element, Event>, value: any) {
    //console.log("handleDocumentSelected event.currentTarget.id: ", event.currentTarget.id);
    //console.log("handleDocumentSelected index: ", value);
    var documentId = Number(event.currentTarget.id);
    setDocumentSelectedIndex(value);

    //console.log("handleDocumentSelected documents: ", documents);
    var document = documents.find((document) => { return document.id === documentId; });
    //console.log("handleDocumentSelected documents.find(document): ", document);
    setDocumentSelected(document);
  }

  function handleSave() {
    //console.log("Save Content: ", editorResult.blocks);
    ASGateway.Documents.UpdateContent(
      version,
      documentSelected.id,
      editorResult.blocks
    )
      .then((dataDocumentContent) => {
        // console.log('UpdateDocumentContent: ', dataDocumentContent);
        // console.log('UpdateDocumentContent documents: ', documents);
        var updatedDocuments = [...documents];
        var documentUpdated = updatedDocuments.find((document) => { return document.id === documentSelected.id; });
        var parsedBlocks = parseDocumentBlocks(dataDocumentContent);
        documentUpdated.blocks = parsedBlocks;

        setDocuments(updatedDocuments);
        setDocumentSelected(documentUpdated);
        setDocumentSelectedData(parsedBlocks);
        setShowEditorResult(false);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }


  // OnLoad
  useEffect(() => {
    //console.log("useEffect[documentSelected]", documentSelected);
    if (documentSelected.blocks && documentSelected.blocks.length > 0) {
      setDocumentSelectedData(null);
      var editorBlocks = parseDocumentBlocks(documentSelected.blocks);
      //console.log("useEffect[documentSelected] DocumentSelectedData", editorBlocks);
      setDocumentSelectedData(editorBlocks);
    }
    else {
      setDocumentSelectedData(null);
    }
  }, [documentSelected]);

  useEffect(() => {
    // console.log("useEffect [DetailContainerNode]");
    // console.log("Props: ", props);
    //Reset Container Data
    if (props.nodeId > 0) {
      setNodeData({} as any);

      //Load Node
      ASGateway.Nodes.GetById(props.nodeId)
        .then((dataNode) => {
          //console.log('GetNodeById: ', dataNode);
          if (dataNode) {
            setNodeData(dataNode);

            ASGateway.Documents.GetForNodeVersion(version, dataNode.id)
              .then((dataDocuments) => {
                //console.log('GetNodeDocumentsForVersion: ', dataDocuments);
                setDocuments(dataDocuments);
                setDocumentSelected(dataDocuments[0]);
                setDocumentSelectedIndex(0);
              })
              .catch((e: any) => {
                console.error(e);
              });
          }
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }, [props.nodeId, version]);

  useEffect(() => {
    // console.log("dataNode: ", nodeData);
    //console.log("dataDocuments: ", documents);
    // console.log("documentSelected: ", documentSelected);
    //console.log("documentSelectedData: ", documentSelectedData);
  })
  // OnRender
  return (
    <>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        <Accordion disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="node-detail-summary"
          >
            <Box sx={{ p: 0, width: "100%", height: "100%" }}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                <Box>
                  <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                    <Box>
                      <Link component={RouterLink} to={`/detail/node/${nodeData.id}`}>
                        <Chip icon={<Grid3x3Icon fontSize="small" color="info" />} label={`${nodeData.id}`} variant="outlined" clickable />
                      </Link>
                    </Box>
                  </Stack>
                </Box>
                <Box sx={{ width: "100%" }}>
                  {nodeData?.name}
                </Box>
                <Box />
              </Stack>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
              <Box>
                == Details ==                         <br />
                  - Included in Networks:             <br />
                    * Network: %Name%                 <br />
                    * Network: %Name%                 <br />
                  - Child Networks:                   <br />
                    * Network: %Name%                 <br />
                    * Network: %Name%                 <br />
              </Box>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="node-detail-documents"
          >
            <Box sx={{ p: 1, width: "100%", height: "100%" }}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                <Box>
                  <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                    <Box>
                      <Badge badgeContent={documents.length} color="info">
                        <DescriptionIcon color="action" />
                      </Badge>
                    </Box>
                  </Stack>
                </Box>
                <Box sx={{ width: "100%" }}>
                  Documents
                </Box>
              </Stack>
            </Box>
            <Divider />
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Box sx={{ p: 0, width: "100%", height: "100%" }}>
                <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                  <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0}>

                    <Tabs variant="scrollable" scrollButtons="auto"
                      value={documentSelectedIndex} //Always Index Tab
                      onChange={handleDocumentSelected}
                    >
                      {documents.map((document, index) => {
                        return (
                          <Tab id={document.id} key={document.id} label={document.name} />
                        );
                      })
                      }
                    </Tabs>

                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={0}>

                      <IconButton size={'small'} sx={{ ":hover": { backgroundColor: "#D4DBF5" } }}
                        onClick={() => setShowNewDocumentForm(true)}
                      >
                        <NoteAddIcon sx={{ transform: "scale(0.7)" }} />
                      </IconButton>
                      <IconButton size={'small'} sx={{ ":hover": { backgroundColor: "#D4DBF5" } }}
                      //onClick={() => setShowMoveToBoardForm(true)}
                      >
                        <DeleteForeverIcon sx={{ transform: "scale(0.7)" }} />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Stack>

                <Divider />
                {documentSelectedData &&
                  <Editor
                    editorId="nodeContainer"
                    contentId={nodeData.id}
                    readOnly={version === 0} //When version is LIVE should block edit
                    markChanges={true}
                    data={documentSelectedData!}
                    editorIsDone={(result) => {
                      if (result) {
                        setEditorResult(result);
                        setShowEditorResult(true);
                      }
                    }}
                  />
                }
              </Box>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="node-detail-changes"
          >
            <Box sx={{ p: 0, width: "100%", height: "100%" }}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                <Box>
                  <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                    <Box>
                      <Badge badgeContent={getTotalChangesOnDocument()} color="info">
                        <FormatListNumberedIcon color="action" />
                      </Badge>
                    </Box>
                  </Stack>
                </Box>
                <Box sx={{ width: "100%" }}>
                  Changes
                </Box>
              </Stack>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                  <Box sx={{ p: 1, width: "100%" }}>
                    Unassigned
                    <Divider />
                  </Box>
                </Stack>
                <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                  <List dense={true}>
                    {getAllChanges(DataBlockChangesFilter.TASKSUNASSIGNED).map((dataBlock: any, indexBlock: number) => {
                      var blockHtml = HTMLParser(DOMPurify.sanitize(dataBlock.data.text));
                      //var elemText = React.createElement("span", { key: dataBlock.apiRef }, blockHtml);
                      // console.log("dataBlock.data.text: ", dataBlock.data.text);
                      // console.log("blockHtml: ", blockHtml);
                      return (
                        <ListItem
                          key={dataBlock.apiRef}
                          dense
                          sx={{ pl: 1, pr: 1, pt: 0, pb: 0 }}
                        >
                          <ListItemButton
                            dense
                            sx={{ pl: 1, pr: 1, pt: 0, pb: 0 }}
                            onClick={() => { return; }}
                          >
                            <ListItemIcon>
                              <IconSvg icon='StatZero' />
                            </ListItemIcon>
                            <ListItemText id={String(dataBlock.apiRef)} primary={blockHtml} />
                            <Chip
                              variant="outlined"
                              size="small"
                              label={getStatusFromEditorBlockResult(dataBlock)}
                              color={getColorFromEditorBlockResult(dataBlock)}
                            />
                            <LaunchIcon fontSize="small"
                              sx={{
                                pl: 1, pr: 1,
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                  <Button
                    onClick={() => setShowAddChangeToTaskForm(true)}
                  >Assign Task</Button>
                </Stack>
              </Card>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                  <Box sx={{ p: 1, width: "100%" }}>
                    Assigned
                    <Divider />
                  </Box>
                </Stack>
                <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                  <List
                    dense
                  >
                    {getAllChanges(DataBlockChangesFilter.TASKSASSIGNED).map((dataBlock: any, indexBlock: number) => {
                      var blockHtml = HTMLParser(DOMPurify.sanitize(dataBlock.data.text));
                      //console.log("dataBlock.tasks.lenght: ", dataBlock.tasks.length);
                      return (
                        <ListItem
                          key={dataBlock.apiRef}
                          dense
                          sx={{ pl: 1, pr: 1, pt: 0, pb: 0 }}
                        >
                          <ListItemButton
                            dense
                            sx={{ pl: 1, pr: 1, pt: 0, pb: 0 }}
                            onClick={(e) => {
                              setAnchorTasksAssignedToChange(e.currentTarget);
                              setTaskIdAssignedToChange(dataBlock.apiRef);
                            }}
                          >
                            <ListItemIcon>
                              <IconSvg icon='StatZero' />
                            </ListItemIcon>
                            <ListItemText id={String(dataBlock.apiRef)} primary={blockHtml} />
                            <Chip
                              variant="outlined"
                              size="small"
                              label={getStatusFromEditorBlockResult(dataBlock)}
                              color={getColorFromEditorBlockResult(dataBlock)}
                            />
                            <Badge badgeContent={dataBlock.tasks.length} color="info"
                              sx={{
                                pl: 1, pr: 1,
                                '& .MuiBadge-badge': {
                                  right: 7,
                                  top: 10,
                                },
                              }}
                            >
                              <ListAltIcon fontSize="small" />
                            </Badge>
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Stack>
              </Card>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>
      {/* MENU TO SHOW TASKS ASSIGNED TO CHANGE */}
      <Menu
        anchorEl={anchorTasksAssignedToChange}
        id="tasks-assigned-to-change"
        open={Boolean(anchorTasksAssignedToChange)}
        onClose={() => { setAnchorTasksAssignedToChange(null); setTaskIdAssignedToChange(null); }}
        onClick={() => { setAnchorTasksAssignedToChange(null); setTaskIdAssignedToChange(null); }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
          Tasks
          <Box sx={{ p: 0, width: "100%" }}>
            <Divider />
          </Box>
        </Stack>

        {getAllChanges(DataBlockChangesFilter.TASKSASSIGNED).map((dataBlock: any, indexBlock: number) => {
          //Check if Change is the one selected
          if (dataBlock.apiRef === taskIdAssignedToChange) {
            //console.log("dataBlock: ", dataBlock);
            return (
              <Box key={dataBlock.apiRef}>
                {dataBlock.tasks?.map((dataBlockTaskId: number, indexBlock: number) => {
                  return (
                    <Link key={dataBlockTaskId} component={RouterLink} to={`/detail/task/${dataBlockTaskId}`}>
                      <MenuItem onClick={() => { return; }}>
                        <Chip icon={<Grid3x3Icon fontSize="small" color="info" />} label={`${dataBlockTaskId}`} variant="outlined" clickable />
                      </MenuItem>
                    </Link>
                  )
                })}
              </Box>
            );
          }
        })}
      </Menu>

      {/* DIALOG FOR NEW DOCUMENT */}
      <Dialog fullScreen={false} open={showNewDocumentForm} onClose={() => setShowNewDocumentForm(false)}>
        <DialogTitle>New Document</DialogTitle>
        <DialogContent sx={{ p: 2, width: "500px", maxHeight: "500px" }}>
          <FormNewDocument
            nodeId={nodeData.id}
            taskId={undefined}
            formIsDone={(newDocCreated: any) => handleNewDocCreated(newDocCreated)}
          />
        </DialogContent>
      </Dialog>

      {/* DIALOG FOR ADD CHANGE TO TASK */}
      <Dialog fullScreen={false} open={showAddChangeToTaskForm} onClose={() => setShowAddChangeToTaskForm(false)}>
        <DialogTitle>Add Changes to Task</DialogTitle>
        <DialogContent sx={{ p: 2, width: "500px", maxHeight: "500px" }}>
          <FormAddChangeToTask
            changes={getAllChanges(DataBlockChangesFilter.TASKSUNASSIGNED)}
            formIsDone={(taskId, changes) => handleChangesAssigned(taskId, changes)}
          />
        </DialogContent>
      </Dialog>

      {/* DIALOG FOR Editor RESULT APPLY CHANGES */}
      <Dialog fullScreen={false} open={showEditorResult} onClose={() => setShowEditorResult(false)} >
        <DialogTitle>Apply changes</DialogTitle>
        <DialogContent sx={{ p: 2, width: "500px", maxHeight: "500px" }}>

          <Fade in={showEditorResult}>
            <Stack direction="column" justifyContent="center" alignItems="stretch" sx={{ p: 0 }} spacing={0}>
              {editorResult.blocks?.map((block: any, index: number) => {
                //console.log("OnSave block:", block);
                if (index === 0) {
                  haveChangesOnSave = false;
                }
                if (hasChangedStatusFromEditorBlockResult(block)) {
                  haveChangesOnSave = true;
                  //Only render the ones with Changed Status
                  return (
                    <Stack
                      id={block.id}
                      key={block.id}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={0}
                    >
                      <Box>
                        <Checkbox size="small" defaultChecked />
                        {HTMLParser(DOMPurify.sanitize(block.data.text))}
                      </Box>
                      <Box>
                        <Chip
                          variant="outlined"
                          size="small"
                          label={getStatusFromEditorBlockResult(block)}
                          color={getColorFromEditorBlockResult(block)}
                        />
                      </Box>
                    </Stack>
                  );
                }
              })}

              {(!haveChangesOnSave) &&
                <Box>
                  No changes Available
                </Box>
              }
            </Stack>
          </Fade>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEditorResult(false)}>{(haveChangesOnSave) ? "Cancel" : "Continue"}</Button>
          {(haveChangesOnSave) && <Button onClick={handleSave}>Save</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}