import React, { useEffect, useState } from 'react'; // let's also import Component
import { useDroppable } from '@dnd-kit/core';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';
import { ASGateway, ASDtos } from 'api/AppServices';

import { IAgileCard } from 'app/AppTypes';
import { TypeMapper } from 'app/TypeMapper';
import { CardTypeEntry } from 'app/AppValues';

import AgileCard from 'components/AgileBoards/AgileCard';
import FormMoveTaskToBoard from 'components/Forms/FormMoveTaskToBoard';
import FormAddTaskToPackage from 'components/Forms/FormAddTaskToPackage';

import {
  Stack,
  Paper,
  Box,
  IconButton,
  Dialog,
  DialogContent,
} from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

type IAgileBoardColumnDragContainerProps = {
  children?: any,
  boardId: number,
  viewId: number,
  columnId: number,
  columnName: string,
  columnBlocked: boolean,
  columnIsFirst: boolean,
  columnIsLast: boolean,
  columnMinHeight: number,
  cards: IAgileCard[],
  setupBoardMode: boolean,
  updateParent: () => void;
};

export default function AgileBoardColumnDragContainer(props: IAgileBoardColumnDragContainerProps) {
  const mapper = new TypeMapper();
  //Redux States
  const dispatch = useAppDispatch();
  const taskIdIsDragging = useAppSelector((state) => state.boardDrawer.taskIdIsDragging);
  //Redux States

  const { isOver, over, node, setNodeRef } = useDroppable({ id: props.columnId });
  const [cards, setCards] = useState<IAgileCard[]>([]);

  const [showMoveToBoardForm, setShowMoveToBoardForm] = useState(false);
  const [showMoveToPackageForm, setShowMoveToPackageForm] = useState(false);

  //Handles
  function handleColumnPosition(increment: number) {
    //Call Service
    ASGateway.BoardViews.UpdateColumnPosition(props.boardId, props.viewId, props.columnId, increment)
      .then((data: ASDtos.BoardViewColumnReadDto) => {
        //console.log('GetAllBoardColumns: ', data);
        props.updateParent();
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  function handleColumnDelete() {
    //Call Service
    ASGateway.BoardViews.DeleteColumn(props.boardId, props.viewId, props.columnId, 0)
      .then((data: any) => {
        //console.log('DeleteBoardColumn: ', data);
        props.updateParent();
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  function isDraggingStarted() {
    // console.log("Column isOver: ", isOver);
    // console.log("Column over: ", over);
    // console.log("Column node: ", node);
    if (taskIdIsDragging > 0) {
      return isOver;
    }
    return false;
  }
  // OnLoad
  useEffect(() => {
    //console.log("Props: ", props);
    //Load Cards
    setCards(props.cards);
  }, [props]);

  // OnRender
  return (
    <>
      <Paper
        id={props.columnId.toString()}
        key={props.columnId.toString()}
        sx={{
          p: 1,
          minWidth: 150,
          width: "100%",
          minHeight: `${props.columnMinHeight}px`,
          textAlign: "left",
          backgroundColor: isDraggingStarted() ? "green" : "#F0F0F0",
          color: isDraggingStarted() ? "white" : undefined,
        }}
        ref={setNodeRef}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
        >
          {props.children}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            sx={{
              mt: 1,
              ml: 2,
              mr: 2,
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{
                mt: 1,
                ml: 2,
                mr: 2,
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="35px">
                {props.columnName.toUpperCase()}
              </Box>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={2}
              sx={{
                mt: 1,
                ml: 2,
                mr: 2,
              }}
            >
              {!props.setupBoardMode && props.columnName.toUpperCase() === "DONE" &&
                <Box>
                  <IconButton
                    size={'small'}
                    sx={{
                      size: "small",
                      ":hover": { backgroundColor: "#D4DBF5" }
                    }}
                    onClick={() => setShowMoveToBoardForm(true)}
                  >
                    <DriveFileMoveIcon sx={{ transform: "scale(0.8)" }} />
                  </IconButton>
                  <IconButton
                    size={'small'}
                    sx={{
                      size: "small",
                      ":hover": { backgroundColor: "#D4DBF5" }
                    }}
                    onClick={() => setShowMoveToPackageForm(true)}
                  >
                    <NoteAddIcon sx={{ transform: "scale(0.8)" }} />
                  </IconButton>
                </Box>
              }
              {props.setupBoardMode &&
                <Box>
                  <IconButton
                    size={'small'}
                    sx={{
                      ":hover": { backgroundColor: "#D4DBF5" }
                    }}
                    onClick={() => handleColumnPosition(-1)}
                    disabled={props.columnBlocked || props.columnIsFirst}
                  >
                    <ArrowBackIosIcon sx={{ transform: "scale(0.8)" }} />
                  </IconButton>
                  <IconButton
                    size={'small'}
                    sx={{
                      ":hover": { backgroundColor: "#D4DBF5" }
                    }}
                    onClick={() => handleColumnDelete()}
                    disabled={props.columnBlocked}
                  >
                    <DeleteForeverIcon sx={{ transform: "scale(0.8)" }} />
                  </IconButton>
                  <IconButton
                    size={'small'}
                    sx={{
                      ":hover": { backgroundColor: "#D4DBF5" }
                    }}
                    onClick={() => handleColumnPosition(1)}
                    disabled={props.columnBlocked || props.columnIsLast}
                  >
                    <ArrowForwardIosIcon sx={{ transform: "scale(0.8)" }} />
                  </IconButton>
                </Box>
              }
            </Stack>
          </Stack>
          {cards.map((cardToLoad, index) => {
            return (
              <AgileCard
                key={cardToLoad.cardId!.toString()}
                card={cardToLoad}
                isSelectable={false}
                isFirst={index === 0}
                isLast={index === cards.length - 1}
                onChangeSelect={(e) => { return }}
              />
            );
          })}
        </Stack>
      </Paper>
      {/* Move to Board Dialog */}
      <Dialog
        fullScreen={false}
        open={showMoveToBoardForm}
        onClose={() => setShowMoveToBoardForm(false)}
        sx={{
        }}
      >
        <DialogContent
          sx={{
            p: 1,
            minWidth: "300px",
            maxWidth: "500px",
          }}
        >
          <FormMoveTaskToBoard
            boardId={props.boardId}
            viewId={props.viewId}
            columnId={props.columnId}
            tasks={mapper.mappCardsToTasks(props.cards)}
            formIsDone={() => { props.updateParent(); setShowMoveToBoardForm(false); }}
          />
        </DialogContent>
      </Dialog>
      {/* Move to Package Dialog */}
      <Dialog
        fullScreen={false}
        open={showMoveToPackageForm}
        onClose={() => setShowMoveToPackageForm(false)}
        sx={{
        }}
      >
        <DialogContent
          sx={{
            p: 1,
            minWidth: "300px",
            maxWidth: "500px",
          }}
        >
          <FormAddTaskToPackage
            boardId={props.boardId}
            columnId={props.columnId}
            tasks={mapper.mappCardsToTasks(props.cards)}
            formIsDone={() => { props.updateParent(); setShowMoveToPackageForm(false); }}
          />
        </DialogContent>
      </Dialog>

    </>
  );
}
