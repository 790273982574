//https://reactscript.com/react-component-highlights-differences-two-strings-react-diff/
//https://github.com/cezary/react-diff

import React, { useRef, useEffect, useState } from 'react';

import SmartButton from 'components/SmartButton';
import {
  Stack,
  Box
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';

import EditorJS, { API, OutputBlockData, BlockMutationEvent, OutputData } from '@editorjs/editorjs';
import { EDITOR_JS_TOOLS } from "app/EditorTools";

// @ts-ignore
import jsdiff from 'diff';
const fnMap: { [key: string]: (a: string | object, b: string | object) => any } = {
  'chars': jsdiff.diffChars,
  'words': jsdiff.diffWords,
  'sentences': jsdiff.diffSentences,
  'json': jsdiff.diffJson
};

type IEditorProps = {
  editorId: string,
  contentId: number,
  readOnly: boolean,
  markChanges: boolean,
  data: any[],
  editorIsDone: (diffEditorResult: any) => void
};

function Editor(props: IEditorProps) {
  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [originalEditorBlocks, setOriginalEditorBlocks] = useState([] as any[]);

  const editorRef = useRef<EditorJS>();
  const editorRefId = useRef<number>();

  async function initEditor(initialLoadData: any) {
    const editor = new EditorJS({
      holder: props.editorId,
      autofocus: true,
      minHeight: 20,
      hideToolbar: true,
      readOnly: props.readOnly,
      // style: { nonce: "" },
      data: initialLoadData,

      inlineToolbar: ['bold', 'italic', 'marker'],
      tools: EDITOR_JS_TOOLS,
      onReady: async () => {
        editorRef.current = editor;

        if (!props.readOnly) {
          var outputData = await editorRef.current?.save();

          //console.log("[EDITOR onReady] outputData.blocks: ", outputData.blocks);
          //Get APIRef for each Block 
          var outputDataWithApiFields = [] as any[];
          outputData.blocks.forEach((outputBlock: any, index: number) => {
            outputDataWithApiFields = [...outputDataWithApiFields,
            {
              ...outputBlock,
              apiRef: props.data[index].apiRef,
              isNew: props.data[index].isNew,
              isDeleted: props.data[index].isDeleted,
              isChanged: props.data[index].isChanged,
              changedApiRef: props.data[index].changedApiRef,
            }];
          })
          //console.log("[EDITOR onReady] outputDataWithApiRef: ", outputDataWithApiRef);

          setOriginalEditorBlocks(outputDataWithApiFields);
        }
      },

      onChange: async (api: API, event: BlockMutationEvent | BlockMutationEvent[]) => {
        return;
      },
    })
  }

  //Handles
  async function handleSaveData(): Promise<any> {
    //console.log("handleSaveData setIsButtonSubmiting(true)");
    setIsButtonSubmiting(true);

    var outputData = await editorRef.current?.save();
    //console.log("OutputData: ", outputData);
    var dataToSave = outputData ? { ...outputData } as any : {} as any;

    dataToSave.blocks.forEach((savedBlock: any, index: number) => {
      var originalEditorBlock = originalEditorBlocks.find((originalEditorBlock) => originalEditorBlock.id === savedBlock.id);
      if (!originalEditorBlock) {
        //NOT FOUND = NEW Block added
        //Add <mark> on all Block
        if (props.markChanges) {
          savedBlock.data.text = '<mark>' + savedBlock.data.text + '</mark>';
        }
        savedBlock.apiRef = -1;
        savedBlock.isNew = true;
        savedBlock.isChanged = false;
        savedBlock.isDeleted = false;
        savedBlock.changedApiRef = null;
        return; //Continue to next Block
      }

      const diff = fnMap['chars'](
        originalEditorBlock.data.text,
        savedBlock.data.text
      );
      //console.log(`diff savedBlock[${savedBlock.id}]: `, diff);

      var diffDataResult = "";
      var isBlockChanged = false;
      diff.forEach((diff: any, index: number) => {
        //Check if nothing changed
        if (!diff.added && !diff.removed) {
          //No Chnge has been made
          diffDataResult += diff.value; //Acumulate Value
          return; //Continue to next diff
        }
        //Check if text was removed
        if (diff.removed) {
          if (props.markChanges) {
            diffDataResult += "<s>" + diff.value + "</s>";
          }
          else {
            diffDataResult += diff.value;
          }
          isBlockChanged = true;
          return; //Continue to next diff
        }

        //Check if text was added
        if (diff.added) {
          if (props.markChanges) {
            diffDataResult += "<mark>" + diff.value + "</mark>";
          }
          else {
            diffDataResult += diff.value;
          }
          isBlockChanged = true;
          return; //Continue to next diff
        }
      });

      //update savedBlock data with diff result
      savedBlock.apiRef = originalEditorBlock.apiRef;
      savedBlock.isNew = false;
      savedBlock.isChanged = isBlockChanged;
      savedBlock.isDeleted = false;
      savedBlock.changedApiRef = originalEditorBlock.changedApiRef;
      savedBlock.data.text = diffDataResult;
    });

    //console.log("Data to Save: ", dataToSave);
    //console.log("handleSaveData setIsButtonSubmiting(false)");
    setIsButtonSubmiting(false);
    return dataToSave;
  }

  // OnLoad
  useEffect(() => {
    //console.log("[EDITOR useEffect] props.contentId: ", props.contentId);
    //console.log("[EDITOR useEffect] editorRef.current: ", editorRef.current);
    //console.log("[EDITOR useEffect] editorRefId.current: ", editorRefId.current);

    if (editorRefId.current && editorRefId.current !== props.contentId) {
      //Destroy old referenced Editor so we can Reload properly a new one
      if (editorRef.current) {
        //console.log("CLEAR()");
        editorRef.current.clear();
        editorRef.current.destroy();
      }
      editorRef.current = undefined;
    }

    //Update RefId
    editorRefId.current = props.contentId;

  }, [props.contentId]);

  useEffect(() => {
    //console.log("[EDITOR useEffect] props.data: ", props.data);

    const initEditorCaller = async (initialLoadData: any) => {
      //console.log("initialLoadData: ", initialLoadData);
      await initEditor(initialLoadData);
      //console.log("END initEditor");
    }

    if (editorRef.current) {  //We have a editor mounted
      //Destroy Editor to Build a new one      
      editorRef.current.clear();
      editorRef.current.destroy();
      editorRef.current = undefined;
    }

    initEditorCaller({
      time: new Date().getTime(),
      blocks: props.data,
      version: "2.1.0"
    });

  }, [props.data]);

  // OnRender
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {/* Editor Container */}
        <Box
          sx={{
            width: "100%",
            height: "500px"
          }}
          style={{
            "textAlign": "justify",
            "overflowX": "hidden",
            "overflowY": "scroll"
          }}
        >
          <div
            id={props.editorId}
          />
        </Box>
      </Stack>
      <SmartButton
        color='primary'
        fullWidth
        disabled={props.readOnly}
        endIcon={<SaveIcon />}
        isSubmiting={isButtonSubmiting}
        onSubmit={async () => {
          var result = await handleSaveData();
          //console.log("Awaited for Saved Data. Editor is Done! Result: ", result);
          props.editorIsDone(result);
        }}
      >Save</SmartButton>
    </Stack>
  );
}

export default Editor;