import React, { useEffect, useState } from 'react'; // let's also import Component
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { useAppDispatch } from 'hooks/StoreReduxHooks';
import { removeUserSession } from 'reducers/appSessionReducer';

import {
  Stack,
  Drawer,
  Box,
  MenuItem,
  Divider,
  IconButton,
  ListItemText,
  ListItemIcon,

} from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AppsIcon from '@mui/icons-material/Apps';
import MapIcon from '@mui/icons-material/Map';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import BallotIcon from '@mui/icons-material/Ballot';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import LogoutIcon from '@mui/icons-material/Logout';

type IMenuBarDrawerProps = {
  pinMenu: boolean,
  showMenu: boolean,
  onClose: () => void
};


export default function MenuBarDrawer(props: IMenuBarDrawerProps) {
  //Redux States
  const dispatch = useAppDispatch();
  //Redux States

  const location = useLocation();

  const [pinMenu, setPinMenu] = useState(props.pinMenu);
  const [showMenu, setShowMenu] = useState(false);

  const [drawerWidth, setDrawerWidth] = useState("15%");
  const [drawerHeight, setDrawerHeight] = useState("100%");

  //Handles

  // OnLoad
  useEffect(() => {
    setShowMenu(props.showMenu);
  }, [props.showMenu]);

  // OnRender
  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          height: drawerHeight,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="left"
        open={pinMenu || showMenu}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
          sx={{ p: 1 }}
        >
          {props.pinMenu === false &&
            <>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Box>
                  <IconButton sx={{ ":hover": { backgroundColor: "#D4DBF5" } }}
                    onClick={props.onClose}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                </Box>
              </Stack>
              <Divider />
            </>
          }
        </Stack>
        <MenuItem selected={false} onClick={() => { dispatch(removeUserSession()); return <Navigate to="/" />; }}>
          <ListItemIcon><LogoutIcon color="info" /></ListItemIcon>
          <ListItemText primary="Logout" style={{ textAlign: "left" }} />
        </MenuItem>
        
        <Divider />
        <MenuItem component={RouterLink} to="/" selected={location.pathname === "/"}>
          <ListItemIcon><AppsIcon color="info" /></ListItemIcon>
          <ListItemText primary="Home" style={{ textAlign: "left" }} />
        </MenuItem>
        <MenuItem component={RouterLink} to="/roadmap" selected={location.pathname === "/roadmap"}>
          <ListItemIcon><MapIcon color="info" /></ListItemIcon>
          <ListItemText primary="Roadmap" style={{ textAlign: "left" }} />
        </MenuItem>
        <MenuItem component={RouterLink} to="/team" selected={location.pathname === "/team"}>
          <ListItemIcon><GroupWorkIcon color="info" /></ListItemIcon>
          <ListItemText primary="Team" style={{ textAlign: "left" }} />
        </MenuItem>
        <Divider />
        <MenuItem disabled={true}>
          <ListItemIcon><BallotIcon color="info" /></ListItemIcon>
          <ListItemText primary="Packages" style={{ textAlign: "left" }} />
        </MenuItem>
        <MenuItem component={RouterLink} to="/setup" selected={location.pathname === "/setup"}>
          <ListItemIcon><SettingsApplicationsIcon color="info" /></ListItemIcon>
          <ListItemText primary="Setup" style={{ textAlign: "left" }} />
        </MenuItem>
      </Drawer>
    </>
  );
};