import React, { ChangeEvent, useEffect, useState } from 'react'; // let's also import Component
import { useForm } from 'react-hook-form';

import { ASGateway, ASDtos } from 'api/AppServices';

import SmartButton from 'components/SmartButton';
import {
  Stack,
  Box,
  TextField,
  MenuItem,
} from '@mui/material';

type IFormNewBoardProps = {
  formIsDone: (newBoardCreated: ASDtos.BoardReadDto) => void;
};

export default function FormNewBoard(props: IFormNewBoardProps) {
  //Redux States
  //const dispatch = useAppDispatch();
  //Redux States

  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'all' });

  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [boardTypes, setBoardTypes] = useState<ASDtos.BoardTypeDto[]>([]);
  const [fieldBoardType, setFieldBoardType] = useState<ASDtos.BoardTypeDto>(ASDtos.BoardTypeDto.Scrum);
  const [fieldBoardName, setFieldBoardName] = useState<string>("");

  //Handles
  function handleChangeBoardType(e: ChangeEvent<HTMLInputElement>) {
    var boardType: any = e.target.value;
    setFieldBoardType(boardType);
  }

  async function handleNewBoardSubmit() {
    //console.log('handleNewBoardSubmit');
    setIsButtonSubmiting(true);
    ASGateway.Boards.Create(
      {
        name: fieldBoardName,
        type: fieldBoardType,
        masterBoard: false
      } as ASDtos.BoardCreateDto
    )
      .then((data: ASDtos.BoardReadDto) => {
        //console.log('CreateBoard: ', data);
        props.formIsDone(data);
      })
      .catch((e: any) => {
        console.error(e);
      })
      .finally(() => {
        setIsButtonSubmiting(false);
      });
  }

  // OnLoad
  useEffect(() => {
    //console.log("useEffect[FormNewBoard]");
    //Load DocumentTypes
    setBoardTypes(
      Object.keys(ASDtos.BoardTypeDto)
        .filter(value => isNaN(Number(value)) === false) //Filter out all keys that are numbers
        .map((key) => ASDtos.BoardTypeDto[key as keyof typeof ASDtos.BoardTypeDto])
    );
    //console.log("useEffect[FormNewBoard]");
  }, []);

  // OnRender
  return (
    <Box id='boardForm' component='form' noValidate autoComplete='off' onSubmit={handleSubmit(handleNewBoardSubmit)} sx={{ padding: 0, width: "100%", height: "100%" }}>
      <TextField
        required
        select
        id="boardType"
        key="boardType"
        label="Board Type"
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...register('boardType', {
          validate: {
            required: (value) => value !== "" || "Field is Required.",
          },
          onChange: (e) => handleChangeBoardType(e),
        })}
        value={fieldBoardType}
        error={errors?.boardType ? true : false}
        helperText={errors?.boardType?.message?.toString()}
      >
        <MenuItem key={""} value={""} disabled>Select Board Type</MenuItem>
        {boardTypes.map((boardType: ASDtos.BoardTypeDto, index) => {
          return (
            <MenuItem key={index} value={boardType}>{boardType}</MenuItem>
          )
        })}
      </TextField>

      <TextField
        required
        id="boardName"
        key="boardName"
        label="Board Name"
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...register('boardName', {
          required: { value: true, message: 'Field is Required.' },
          maxLength: { value: 40, message: 'Maximum characters for field is 40.' },
          onChange: (e) => setFieldBoardName(e.target.value),
        })}
        value={fieldBoardName}
        error={errors?.boardName ? true : false}
        helperText={errors?.boardName?.message?.toString()}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <br /><br /><br />
        <SmartButton isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleNewBoardSubmit)}>Create</SmartButton>
      </Stack>
    </Box>
  );
}