import React, { useEffect, useState } from 'react'; // let's also import Component
import { useForm, Controller } from 'react-hook-form';

import { useAppSelector, useAppDispatch } from 'hooks/StoreReduxHooks';

import { ICalendarRangeProps } from 'app/AppTypes';
import { ScreenWideEntry } from 'app/AppValues';
import { ASGateway, ASDtos } from 'api/AppServices';

import {
  Stack,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  SelectChangeEvent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Button
} from '@mui/material';

type ISelectorTeamsProps = {
  agentId?: number,
  onChangeTeams: (teamsSelected: number[]) => void
};

export default function SelectorTeams(props: ISelectorTeamsProps) {
  //Redux States
  const dispatch = useAppDispatch();
  // const version = useAppSelector((state) => state.systemBar.version);
  //Redux States

  const [teams, setTeams] = useState<ASDtos.TeamReadDto[]>([]);
  const [teamSelected, setTeamSelected] = useState<string>("");

  //Handles
  function handleChangeTeam(teamId: string) {
    //console.log("handleChangeTeam teamId: ", teamId);
    setTeamSelected(teamId);
  }

  useEffect(() => {
    if (props.agentId) {
      ASGateway.Teams.GetAllForAgent(props.agentId)
      .then((data: ASDtos.TeamReadDto[]) => {
        //console.log('GetAllTeams: ', data);
        setTeams(data);
        if (data.length > 0) {
          handleChangeTeam(data[0].id.toString()); //Always select first one by default 
        }
      })
      .catch((e: any) => {
        console.error(e);
      });

    }
    else{
      ASGateway.Teams.GetAll()
      .then((data: ASDtos.TeamReadDto[]) => {
        //console.log('GetAllTeams: ', data);
        setTeams(data);
        if (data.length > 0) {
          handleChangeTeam(data[0].id.toString()); //Always select first one by default 
        }
      })
      .catch((e: any) => {
        console.error(e);
      });

    }
  }, []);

  useEffect(() => {
    props.onChangeTeams([Number(teamSelected)]);
  }, [teamSelected]);

  // OnRender
  return (
    <Box sx={{ p: 1 }} >
      <FormControl >
        <InputLabel sx={{ height: 30 }} id="team-select-label">Team</InputLabel>
        <Select
          sx={{ height: 30, width: 300 }}
          labelId="team-select-label"
          id="team-select"
          value={teamSelected}
          label="Team"
          onChange={(event: any) => {
            handleChangeTeam(event.target.value);
          }}
        >
          {teams.map((t: ASDtos.TeamReadDto) => (
            <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}