import { ASDtos } from 'api/AppServices';
import { IAgileCard } from 'app/AppTypes';
import { CardTypeEntry } from 'app/AppValues';

export class TypeMapper {
  constructor(){};

  mappCardsToTasks(cards: IAgileCard[]) : ASDtos.TaskReadDto[] {
    var tasksMapped: ASDtos.TaskReadDto[] = [];
    cards?.forEach((card: IAgileCard) => {
      tasksMapped = [...tasksMapped, { 
                                        id: card.cardId!, 
                                        type: card.cardTypeSource as unknown as ASDtos.TaskTypeDto,
                                        priority: card.cardPriority as unknown as ASDtos.TaskPriorityDto,
                                        title: card.cardTitle, 
                                        description: 
                                        card.cardDescription! 
                                      }];
    });
    return tasksMapped;
  }
  mappTasksToCards(tasks: ASDtos.TaskReadDto[], cardTypeForAll: CardTypeEntry) : IAgileCard[] {
    var cardsMapped: IAgileCard[] = [];
    tasks?.forEach((task: ASDtos.TaskReadDto) => {
      cardsMapped = [...cardsMapped, { 
        cardId: task.id, 
        cardTypeSource: task.type.toString(),
        cardPriority: task.priority.toString(),
        cardTitle: task.title, 
        cardDescription: task.description, 
        cardType: cardTypeForAll, 
        cardVersion: task.package?.version.name,
        cardStatus: "",
        cardTasks:  [{
            id: task.id, 
            environments: task.package?.environments.map(x => x.id)
          } as ASDtos.NodeTaskReadDto 
        ]
      }];
    });
    return cardsMapped;
  }

  mappPackagesToCards(packs: ASDtos.PackageReadDto[]) : IAgileCard[] {
    var cardsMapped: IAgileCard[] = [];
    packs?.forEach((pack: ASDtos.PackageReadDto) => {
      cardsMapped = [...cardsMapped, { 
        cardId: pack.id, 
        cardTypeSource: "",
        cardPriority: "",
        cardTitle: pack.name, 
        cardDescription: "", 
        cardType: (pack.id < 0)? CardTypeEntry.PACKAGEENVIRONMENTSTATUS : CardTypeEntry.PACKAGE, 
        cardVersion: (pack.version?.name)?pack.version.name:"", 
        cardStatus: (pack.status)?ASDtos.PackageStatusDto[pack.status]:"", 
        cardTasks: pack.tasks?.map((task) => { 
          return {
            id: task, 
            environments: pack.environments.map(x => x.id)
          } as ASDtos.NodeTaskReadDto 
        }) 
      }];
    });
    return cardsMapped;
  }

}