import React, { useEffect, useState } from 'react'; // let's also import Component
import { DndContext } from '@dnd-kit/core';

import { ASGateway, ASDtos } from 'api/AppServices';

import {  useAppDispatch } from 'hooks/StoreReduxHooks';
import { setTaskIdIsDragging } from 'reducers/boardDrawerReducer';

import { CardTypeEntry } from 'app/AppValues';
import { TypeMapper } from 'app/TypeMapper';

import AgileBoardColumn from 'components/AgileBoards/AgileBoardColumnDragContainer';


import {
  Stack,
  Box,
} from '@mui/material';

type IBoardViewKanbanProps = {
  children?: any,
  boardId: number,
  boardViewId: number,
  maxHeightContainer: number,
  setupBoardMode: boolean,
};

export default function BoardViewKanban(props: IBoardViewKanbanProps) {
  const mapper = new TypeMapper();
  //Redux States
  const dispatch = useAppDispatch();
  //Redux States

  const [selectedBoardId, setSelectedBoardId] = useState(0);
  const [selectedBoardViewId, setSelectedBoardViewId] = useState(0);
  const [setupBoardMode, setSetupBoardMode] = useState(false);
  const [boardViewColumns, setBoardViewColumns] = useState([] as ASDtos.BoardViewColumnReadDto[]);

  //Handles
  // function handleDragStart(event: DragStartEvent) {
  function handleDragStart(event: any) {
    //console.log("handleDragStart(event): ", event);
    if (event.over) { //&& event.over.id === 'droppable'
      //var msg = "Card ID: " + event.active.id + " -> Dropped on Column: " + event.over.id;
      //alert(msg);
    }
  }
  // function handleDragMove(event: DragMoveEvent) {
  function handleDragMove(event: any) {
    //console.log("handleDragMove(event): ", event);
    if (event.active) {
      dispatch(setTaskIdIsDragging(event.active.id))
    }
  }
  // function handleDragOver(event: DragOverEvent) {
  function handleDragOver(event: any) {
    //console.log("handleDragOver(event): ", event);

    dispatch(setTaskIdIsDragging(0))
  }
  // function handleDragEnd(event: DragEndEvent) {
  function handleDragEnd(event: any) {
    //console.log("handleDragEnd(event): ", event);
    //console.log("event.delta): ", event.delta);

    if (event.over) {
      const transitionToColumnId = event.over.id;
      const taskId = event.active.id;

      //=== Update Locally before saving to Database ===
      //Get ORIGIN Column from which task was moved FROM
      var currentBoardViewColumns = [...boardViewColumns];
      var columnOriginTask = currentBoardViewColumns.find((column) => {
        return column.tasks.some((task: any) => task.id === taskId);
      })!;

      //Get DESTINY Column from which task was moved INTO
      var columnDestinyTask = currentBoardViewColumns.find((column) => {
        return column.id === transitionToColumnId;
      })!;

      //Get Task to move
      var taskToMove = columnOriginTask?.tasks.find((task: any) => {
        return task.id === taskId;
      })!;

      //Remove task from Column Origin
      columnOriginTask.tasks = columnOriginTask?.tasks.filter((task: any) => task.id !== taskId);
      //Add task to Column Destiny
      columnDestinyTask.tasks = [...columnDestinyTask.tasks, taskToMove];

      //Save UI Result
      setBoardViewColumns(currentBoardViewColumns);

      //Update Database
      ASGateway.Tasks.AssignToColumn(
        selectedBoardId,
        selectedBoardViewId,
        columnOriginTask?.id,
        taskId,
        {
          assignToBoardId: selectedBoardId, //Same Board
          assignToViewId: selectedBoardViewId, //Same Board View
          assignToColumnId: transitionToColumnId, //New Column
        }
      )
        .then((data: ASDtos.TaskReadDto) => {
          //console.log('UpdateTaskTransitionToColumn: ', data);
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }


  async function handleLoadBoardView() {
    //Call Service
    await ASGateway.BoardViews.GetColumns(selectedBoardId, selectedBoardViewId)
      .then((data: ASDtos.BoardViewColumnReadDto[]) => {
        //console.log('GetAllBoardColumns: ', data);
        setBoardViewColumns(data);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }


  // OnLoad
  useEffect(() => {
    //Call Service
    handleLoadBoardView()
      .then((data: any) => {
        setSelectedBoardId(props.boardId);
        setSelectedBoardViewId(props.boardViewId);
        setSetupBoardMode(props.setupBoardMode);
      })
      .catch((e: any) => {
        console.error(e);
      });

  }, [props]);

  // OnRender
  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <Box width="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
          sx={{
            mt: 1,
            ml: 2,
            mr: 2,
            height: "95%",
          }}
        >
          {boardViewColumns.sort((a, b) => a.position > b.position ? 1 : -1).map((column, index) => {
            // console.log("index: ", index );
            // console.log("boardColumns.length: ", boardColumns.length );
            // console.log("columnIsFirst: ", index >= 1 );
            // console.log("columnIsLast: ", index < (boardColumns.length - 2) );
            return (
              <AgileBoardColumn
                key={column.id}
                boardId={selectedBoardId}
                viewId={selectedBoardViewId}
                columnId={column.id}
                columnName={column.name}
                columnBlocked={column.isBlocked}
                columnIsFirst={index === 1} //Zero Based
                columnIsLast={index === (boardViewColumns.length - 2)} //Zero Based
                columnMinHeight={props.maxHeightContainer - 100}
                cards={mapper.mappTasksToCards(column.tasks, CardTypeEntry.BOARD)}
                setupBoardMode={setupBoardMode}
                updateParent={handleLoadBoardView}
              />
            );
          })}
        </Stack>
      </Box>
    </DndContext>
  );
}