import React, { ChangeEvent, useEffect, useState } from 'react'; // let's also import Component
import { useForm } from 'react-hook-form';

import { ASGateway, ASDtos } from 'api/AppServices';

import SmartButton from 'components/SmartButton';
import {
  Stack,
  Box,
  Typography,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel
} from '@mui/material';

type IFormMoveTaskToBoardProps = {
  boardId: number,
  viewId: number,
  columnId: number,
  tasks: ASDtos.TaskReadDto[],
  formIsDone: () => void;
};

export default function FormMoveTaskToBoard(props: IFormMoveTaskToBoardProps) {
  const [boards, setBoards] = useState<ASDtos.BoardReadDto[]>([]);
  const [boardViews, setBoardViews] = useState<ASDtos.BoardViewReadDto[]>([]);
  const [boardViewColumns, setBoardViewColumns] = useState<ASDtos.BoardViewColumnReadDto[]>([]);

  const [isButtonSubmiting, setIsButtonSubmiting] = useState<boolean>(false);
  const [fieldBoard, setFieldBoard] = useState("");
  const [fieldBoardView, setFieldBoardView] = useState("");

  const [fieldTasks, setFieldTasks] = useState<number[]>([]);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: 'all',
    // defaultValues: {
    //   board: fieldBoard,
    //   boardView: ""
    // }
  });


  //Handles
  function handleChangeBoard(e: any) {
    setFieldBoard(e.target.value);
    loadFormOnChangeBoard(e.target.value);
  }

  function loadFormOnChangeBoard(selectedBoardId: string) {
    //Reset Views Selection
    setBoardViews([]);
    setFieldBoardView("");

    //Get Board Columns
    var boardId = Number(selectedBoardId);
    if (boardId) {
      ASGateway.BoardViews.GetAll(boardId)
        .then((data: ASDtos.BoardViewReadDto[]) => {
          //console.log('GetAllBoardColumns: ', data);
          setBoardViews(data);
          loadFormOnChangeBoardView("");
        })
        .catch((e: any) => {
          console.error(e);
        });
    }

  }

  function handleChangeBoardView(e: any) {
    setFieldBoardView(e.target.value);
    loadFormOnChangeBoardView(e.target.value);
  }

  function loadFormOnChangeBoardView(selectedBoardViewId: string) {
    //Reset Columns Selection
    setBoardViewColumns([]);
    //setFieldBoardViewColumn("");

    //Get Board Columns
    var boardViewId = Number(selectedBoardViewId);
    if (boardViewId) {
      ASGateway.BoardViews.GetColumns(Number(fieldBoard), boardViewId)
        .then((data: ASDtos.BoardViewColumnReadDto[]) => {
          //console.log('GetAllBoardColumns: ', data);
          setBoardViewColumns(data);
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }

  function handleChangeTaskSelection(e: ChangeEvent<HTMLInputElement>, taskId: number) {
    var isChecked = e.target.checked;
    if (isChecked) {
      //Add to fieldTasks
      setFieldTasks([...fieldTasks, taskId]);
    }
    else {
      //Remove from fieldTasks
      setFieldTasks([...fieldTasks.filter((task) => task !== taskId)]);
    }
  }

  async function handleSubmitForm() {
    setIsButtonSubmiting(true);
    for (const taskId of fieldTasks) { //for Lopp waits for our async call
      await ASGateway.Tasks.AssignToColumn(
        props.boardId,
        props.viewId,
        props.columnId,
        taskId,
        {
          assignToBoardId: Number(fieldBoard),
          assignToViewId: Number(fieldBoardView),
          assignToColumnId: boardViewColumns[0].id //Number(fieldBoardViewColumn)
        }
      );
    };

    setIsButtonSubmiting(false);
    props.formIsDone();
  }

  // OnLoad
  useEffect(() => {
    //console.log("Props: ", props);
    //By Default all Tasks are selected
    setFieldTasks([...props.tasks.map(task => task.id)]);

    //Load Boards
    ASGateway.Boards.GetAll()
      .then((data: ASDtos.BoardReadDto[]) => {
        //console.log('GetAllBoards: ', data);
        setBoards(data);
        setFieldBoard(props.boardId.toString());
        setValue('board', props.boardId.toString());
        loadFormOnChangeBoard(props.boardId.toString());
      })
      .catch((e: any) => {
        console.error(e);
      });

  }, [props]);

  // OnRender
  return (
    <Box id='moveToBoardForm' component='form' noValidate autoComplete='off' onSubmit={handleSubmit(handleSubmitForm)} sx={{ padding: 0, width: "100%", height: "100%" }}>
      <Typography variant="h5" sx={{ p: 1 }}>
        Move To Board
      </Typography>
      <TextField
        required
        select
        id="board"
        key="board"
        label="Board"
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...register('board', {
          validate: {
            required: (value) => value !== "" || "Field is Required.",
          },
          onChange: (e) => handleChangeBoard(e),
        })}
        value={fieldBoard}
        error={errors?.board ? true : false}
        helperText={errors?.board?.message?.toString()}
      >
        <MenuItem key={""} value={""} disabled>Select Board</MenuItem>
        {boards.map((board: ASDtos.BoardReadDto) => {
          // if (board.id !== props.boardId){ //Only load Baords different than the one we are moving tasks from
          return (
            <MenuItem key={board.id} value={board.id}>{board.name}</MenuItem>
          )
          // }
        })}
      </TextField>
      <TextField
        required
        select
        id="boardView"
        key="boardView"
        label="View"
        fullWidth autoFocus margin="dense" variant='filled' size='small'
        {...register('boardView', {
          validate: {
            required: (value) => value !== "" || "Field is Required.",
          },
          onChange: (e) => handleChangeBoardView(e),
        })}
        value={fieldBoardView}
        error={errors?.boardView ? true : false}
        helperText={errors?.boardView?.message?.toString()}
      >
        <MenuItem key={""} value={""} disabled>Select View</MenuItem>
        {boardViews.map((boardView: ASDtos.BoardViewReadDto) => {
          return (
            <MenuItem key={boardView.id} value={boardView.id}>{boardView.name}</MenuItem>
          )
        })}
      </TextField>
      <br /><br />
      {props.tasks.map((task) => {
        return (
          <Box key={task.id}>
            <FormControlLabel
              label={`#${task.id} ${task.title}`}
              control={
                <Checkbox
                  checked={fieldTasks.some(taskId => taskId === task.id)}
                  onChange={(e) => handleChangeTaskSelection(e, task.id)}
                />
              }
            />
          </Box>
        );
      })}
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <br /><br /><br />
        <SmartButton isSubmiting={isButtonSubmiting} onSubmit={handleSubmit(handleSubmitForm)}>Move</SmartButton>
      </Stack>
    </Box>
  );
}