import { ASDtos } from 'api/AppServices/index';
import CallService from 'api/AppServices/AppServicesClient';


export async function GetForNodeVersion(version: number, nodeId: number): Promise<ASDtos.DocumentReadDto[]> {
  var urlResource = `/versions/${version}/nodes/${nodeId}/documents`;
  //console.log('GetNodeDocumentsForVersion URL: ', { url: urlResource });
  return await CallService<ASDtos.DocumentReadDto[]>(urlResource);
}

export async function GetForNode(nodeId: number): Promise<ASDtos.DocumentReadDto> {
  var urlResource = `/nodes/${nodeId}/documents`;
  //console.log('GetNodeDocuments URL: ', { url: urlResource });

  return await CallService<ASDtos.DocumentReadDto>(urlResource);
}

export async function Create( documentName: string, nodeId: number | undefined, taskId: number | undefined): Promise<ASDtos.DocumentReadDto> {
  var urlResource = `/documents`;
  //console.log('UpdateDocumentContent URL: ', { url: urlResource });

  var payload: ASDtos.DocumentCreateDto = {
    name: documentName,
    node: nodeId,
    task: taskId
  };

  //console.log('UpdateDocumentContent PayLoad: ', payload);
  return await CallService<ASDtos.DocumentReadDto>(urlResource, "POST", payload);
}

export async function UpdateContent(
  version: number,
  documentId: number,
  blocks: any[]
): Promise<ASDtos.DocumentBlockReadDto[]> {

  var urlResource = `/versions/${version}/documents/${documentId}/blocks`;
  //console.log('UpdateDocumentContent URL: ', { url: urlResource });

  //Build DocumentBlock payload
  var payload: ASDtos.DocumentBlockUpdateDto[] = [];
  blocks.forEach((block: any, index: number) =>{
    payload = [...payload, 
      {
        apiRef: block.apiRef,
        type: block.type,
        data: JSON.stringify(block.data),
        order: index+1,
        isNew: block.isNew,
        isDeleted: block.isDeleted,
        isChanged: block.isChanged,
        changedApiRef: block.changedApiRef
    } as ASDtos.DocumentBlockUpdateDto];
  });

  //console.log('UpdateDocumentContent PayLoad: ', payload);
  return await CallService<ASDtos.DocumentBlockReadDto[]>(urlResource, "PUT", payload);
}
